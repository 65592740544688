import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import styled from 'styled-components';
import Preloader from '../components/Preloader'; // Import the Preloader component

const Dashboard = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState('');
  const [fee, setFee] = useState('');
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [loading, setLoading] = useState(false); // Add a loading state

  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    // Fetch courses from Firestore
    const fetchCourses = async () => {
      setLoading(true); // Set loading to true
      try {
        const querySnapshot = await getDocs(collection(db, 'courses'));
        const coursesList = [];
        querySnapshot.forEach((doc) => {
          coursesList.push({ id: doc.id, ...doc.data() });
        });
        setCourses(coursesList);
      } catch (error) {
        console.error('Error fetching courses:', error);
      } finally {
        setLoading(false); // Set loading to false
      }
    };

    fetchCourses();
  }, [db]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCourse && !image) {
      alert('Please upload an image.');
      return;
    }

    const uploadImageAndSaveCourse = async (imageUrl) => {
      try {
        setLoading(true); // Set loading to true
        if (selectedCourse) {
          // Update existing course
          await updateDoc(doc(db, 'courses', selectedCourse.id), {
            title,
            description,
            duration,
            fee,
            imageUrl,
          });
          const updatedCourses = courses.map(course =>
            course.id === selectedCourse.id ? { ...course, title, description, duration, fee, imageUrl } : course
          );
          setCourses(updatedCourses);
          alert('Course updated successfully!');
        } else {
          // Add new course
          const newCourse = {
            title,
            description,
            duration,
            fee,
            imageUrl,
          };
          const docRef = await addDoc(collection(db, 'courses'), newCourse);
          setCourses([...courses, { id: docRef.id, ...newCourse }]);
          alert('Course added successfully!');
        }
        resetForm();
      } catch (error) {
        console.error('Error adding/updating document:', error);
        alert('Failed to add/update course. Please try again.');
      } finally {
        setLoading(false); // Set loading to false
      }
    };

    if (image) {
      const imageRef = ref(storage, `courses/${image.name}`);
      const uploadTask = uploadBytesResumable(imageRef, image);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.error('Upload failed:', error);
          alert('Upload failed. Please try again.');
          setLoading(false); // Set loading to false in case of error
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            uploadImageAndSaveCourse(downloadURL);
          });
        }
      );
    } else if (selectedCourse) {
      uploadImageAndSaveCourse(selectedCourse.imageUrl);
    } else {
      const defaultImageUrl = 'default_image_url'; // Replace with your default image URL
      uploadImageAndSaveCourse(defaultImageUrl);
    }
  };

  const handleEdit = (course) => {
    setSelectedCourse(course);
    setTitle(course.title);
    setDescription(course.description);
    setDuration(course.duration);
    setFee(course.fee);
  };

  const handleDelete = async (courseId) => {
    if (window.confirm('Are you sure you want to delete this course?')) {
      setLoading(true); // Set loading to true
      try {
        await deleteDoc(doc(db, 'courses', courseId));
        setCourses(courses.filter(course => course.id !== courseId));
        alert('Course deleted successfully!');
      } catch (error) {
        console.error('Error deleting course:', error);
        alert('Failed to delete course. Please try again.');
      } finally {
        setLoading(false); // Set loading to false
      }
    }
  };

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setDuration('');
    setFee('');
    setImage(null);
    setProgress(0);
    setSelectedCourse(null);
  };

  return (
    <>
      <PreloaderComponent loading={loading} /> {/* Render preloader based on loading state */}
      <DashboardContainer>
        <AddCourseContainer>
          <h2>{selectedCourse ? 'Edit Course' : 'Add New Course'}</h2>
          <FormContainer onSubmit={handleSubmit}>
            <FormInput
              type="text"
              placeholder="Course Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <FormTextArea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
            <FormInput
              type="text"
              placeholder="Duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            />
            <FormInput
              type="text"
              placeholder="Fee"
              value={fee}
              onChange={(e) => setFee(e.target.value)}
              required
            />
            <FormInput type="file" accept="image/*" onChange={(e) => setImage(e.target.files[0])} />
            <ProgressBar progress={progress} />
            <SubmitButton type="submit">{selectedCourse ? 'Update Course' : 'Add Course'}</SubmitButton>
          </FormContainer>
        </AddCourseContainer>

        <CoursesContainer>
          <h2>Existing Courses</h2>
          <CoursesList>
            {courses.length > 0 ? (
              courses.map((course) => (
                <CourseCard key={course.id}>
                  <CourseImage src={course.imageUrl} alt={course.title} />
                  <CourseInfo>
                    <h3>{course.title}</h3>
                    <p>{course.description}</p>
                    <p><strong>Duration:</strong> {course.duration}</p>
                    <p><strong>Fee:</strong> ₹{course.fee}</p>
                    <ButtonContainer>
                      <EditButton onClick={() => handleEdit(course)}>Edit</EditButton>
                      <DeleteButton onClick={() => handleDelete(course.id)}>Delete</DeleteButton>
                    </ButtonContainer>
                  </CourseInfo>
                </CourseCard>
              ))
            ) : (
              <p>No courses available.</p>
            )}
          </CoursesList>
        </CoursesContainer>
      </DashboardContainer>
    </>
  );
};

// Preloader Component
const PreloaderComponent = ({ loading }) => {
  return loading ? <Preloader /> : null; // Render Preloader if loading is true
};


const DashboardContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  // padding: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const AddCourseContainer = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const FormContainer = styled.form`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
`;

const FormTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: teal;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: darkcyan;
  }
`;

const ProgressBar = styled.div`
  height: 20px;
  background: lightgrey;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;

  &::before {
    content: '';
    display: block;
    height: 100%;
    width: ${({ progress }) => `${progress}%`};
    background: teal;
    transition: width 0.3s;
  }
`;

const CoursesContainer = styled.div`
  flex: 1;
  margin-top: 20px;
`;

const CoursesList = styled.div`
  max-height: 400px; /* Example height, adjust as needed */
  overflow-y: auto;
`;

const CourseCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CourseImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 20px;
`;

const CourseInfo = styled.div`
  flex: 1;

  h3 {
    margin: 0 0 5px;
    font-size: 1.2rem;
  }

  p {
    margin: 0 0 10px;
    color: #555;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
`;

const EditButton = styled.button`
  padding: 8px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-color: #2980b9;
  }
`;

const DeleteButton = styled.button`
  padding: 8px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #c0392b;
  }
`;

export default Dashboard;
