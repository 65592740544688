import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { db, storage } from "../firebase"; // Adjust the path as per your project structure
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useParams} from "react-router-dom";
import Preloader from "../components/Preloader"; // Adjust the path as per your project structure

const AddCertificate = () => {
  const { userId } = useParams(); // Get user ID from URL
  const [certificateName, setCertificateName] = useState("");
  const [dateIssued, setDateIssued] = useState("");
  const [issuer, setIssuer] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [certificates, setCertificates] = useState([]);

  // Fetch the existing certificates for the user
  useEffect(() => {
    const fetchCertificates = async () => {
      setLoading(true);
      try {
        const certificatesRef = collection(db, `studentscertificates`);
        const q = query(certificatesRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        const certificateList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCertificates(certificateList);
      } catch (err) {
        setError("Error fetching certificates.");
        console.error("Error fetching certificates:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchCertificates();
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let imageUrl = "";
      if (image) {
        const storageRef = ref(storage, `studentCertificates/${userId}/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              setError("Error uploading image.");
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                imageUrl = downloadURL;
                resolve();
              });
            }
          );
        });
      }

      const certificatesRef = collection(db, `studentscertificates`);
      await addDoc(certificatesRef, {
        certificateName,
        dateIssued,
        issuer,
        userId,
        imageUrl,
      });
      // Fetch the updated list of certificates after adding a new one
      const q = query(certificatesRef, where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      const updatedCertificateList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCertificates(updatedCertificateList);
      setCertificateName("");
      setDateIssued("");
      setIssuer("");
      setImage(null);
    } catch (err) {
      setError("Error adding certificate.");
      console.error("Error adding certificate:", err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <Container>
      <Title>Add Certificate for User ID: {userId}</Title>
      <ContentWrapper>
        <FormWrapper>
          <DetailsTitle>Add Certificates Form</DetailsTitle>
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Certificate Name"
              value={certificateName}
              onChange={(e) => setCertificateName(e.target.value)}
              required
            />
            <Input
              type="date"
              placeholder="Date Issued"
              value={dateIssued}
              onChange={(e) => setDateIssued(e.target.value)}
              required
            />
            <Input
              type="text"
              placeholder="Issuer"
              value={issuer}
              onChange={(e) => setIssuer(e.target.value)}
              required
            />
            <FileInput
              type="file"
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Button type="submit">Add Certificate</Button>
          </Form>
        </FormWrapper>
        <DetailsWrapper>
          <DetailsTitle>Certificates List</DetailsTitle>
          {certificates.length === 0 ? (
            <NoDataMessage>No certificates found for this user.</NoDataMessage>
          ) : (
            <CertificatesList>
              {certificates.map((certificate) => (
                <CertificateItem key={certificate.id}>
                  <CertificateDetails>
                    <strong>Name:</strong> {certificate.certificateName}
                  </CertificateDetails>
                  <CertificateDetails>
                    <strong>Date Issued:</strong> {certificate.dateIssued}
                  </CertificateDetails>
                  <CertificateDetails>
                    <strong>Issuer:</strong> {certificate.issuer}
                  </CertificateDetails>
                  {certificate.imageUrl && (
                    <CertificateImage src={certificate.imageUrl} alt="Certificate" />
                  )}
                </CertificateItem>
              ))}
            </CertificatesList>
          )}
        </DetailsWrapper>
      </ContentWrapper>
    </Container>
  );
};

// Styled components
const Container = styled.div`
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FormWrapper = styled.div`
  flex: 1;
  margin-right: 20px;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const DetailsWrapper = styled.div`
  flex: 1;
  margin-left: 20px;

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const FileInput = styled.input`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

const DetailsTitle = styled.h2`
  font-size: 20px;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const CertificatesList = styled.div`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const CertificateItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }
`;

const CertificateDetails = styled.p`
  margin: 5px 0;
`;

const CertificateImage = styled.img`
  width: 100%;
  max-width: 200px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
`;

const NoDataMessage = styled.p`
  text-align: center;
  color: #555;
`;

export default AddCertificate;
