import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";
import Preloader from "../components/Preloader"; // Import Preloader component

const UserFees = () => {
  const { userid } = useParams(); // Get userid from URL parameter
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({
    courses: [], // Initialize courses array
    fees: [], // Initialize fees array
  });
  const [error, setError] = useState(null);
  const [feeDetails, setFeeDetails] = useState({
    amount: "",
    description: "",
    date: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(db, "students", userid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUserDetails({
            courses: docSnap.data().courses || [],
            fees: docSnap.data().fees || [],
          });
        } else {
          setError("User not found");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setError("Error fetching user details");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeeDetails((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddFee = async (e) => {
    e.preventDefault();

    try {
      const feeData = {
        amount: parseFloat(feeDetails.amount),
        description: feeDetails.description,
        date: feeDetails.date,
      };

      const userRef = doc(db, "students", userid);
      await updateDoc(userRef, {
        fees: arrayUnion(feeData),
      });

      setUserDetails((prevData) => ({
        ...prevData,
        fees: [...(prevData.fees || []), feeData],
      }));

      setFeeDetails({
        amount: "",
        description: "",
        date: "",
      });

      alert("Fee added successfully!");
    } catch (error) {
      console.error("Error adding fee:", error);
      setError("Error adding fee");
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  // Calculate fees summary
  const totalCourseFees = userDetails.courses.reduce((total, course) => total + parseFloat(course.courseFees), 0);
  const totalFeesReceived = userDetails.fees.reduce((total, fee) => total + parseFloat(fee.amount), 0);
  const remainingFees = totalCourseFees - totalFeesReceived;

  return (
    <div style={styles.container}>
      <div style={styles.section}>
        <h2>Fees Summary</h2>
        <p><strong>Total Course Fees:</strong> {totalCourseFees.toLocaleString("en-IN")} INR</p>
        <p><strong>Total Fees Received:</strong> {totalFeesReceived.toLocaleString("en-IN")} INR</p>
        <p><strong>Remaining Fees:</strong> {remainingFees.toLocaleString("en-IN")} INR</p>
      </div>

      <div style={styles.section}>
        {/* Add fee form */}
        <h2>Add Fee</h2>
        <form onSubmit={handleAddFee} style={styles.form}>
          <input
            type="number"
            name="amount"
            placeholder="Amount"
            value={feeDetails.amount}
            onChange={handleChange}
            required
            style={styles.input}
          />
          <input
            type="text"
            name="description"
            placeholder="Description"
            value={feeDetails.description}
            onChange={handleChange}
            required
            style={styles.input}
          />
          <input
            type="date"
            name="date"
            placeholder="Date"
            value={feeDetails.date}
            onChange={handleChange}
            required
            style={styles.input}
          />
          <button type="submit" style={styles.button}>
            Add Fee
          </button>
        </form>
      </div>

      <div style={styles.section}>
        {/* Display course list */}
        <h2>Courses</h2>
        {userDetails.courses.length > 0 ? (
          <ul style={styles.list}>
            {userDetails.courses.map((course, index) => (
              <li key={index} style={styles.listItem}>
                <p><strong>Center Name:</strong> {course.centerName}</p>
                <p><strong>Course Name:</strong> {course.courseName}</p>
                <p><strong>Course Fees:</strong> {course.courseFees}</p>
                <p><strong>University:</strong> {course.university}</p>
                <p><strong>Enrollment Session:</strong> {course.enrollmentSession}</p>
                <p><strong>Center Head:</strong> {course.centerHead}</p>
                <p><strong>Start Date:</strong> {course.startDate}</p>
                <p><strong>End Date:</strong> {course.endDate}</p>
                {course.examFees && <p><strong>Exam Fees:</strong> {course.examFees}</p>}
              </li>
            ))}
          </ul>
        ) : (
          <p>No courses added yet.</p>
        )}
      </div>

      <div style={styles.section}>
        {/* Display fees list */}
        <h2>Fees</h2>
        {userDetails.fees.length > 0 ? (
          <ul style={styles.list}>
            {userDetails.fees.map((fee, index) => (
              <li key={index} style={styles.listItem}>
                <p><strong>Amount:</strong> {fee.amount}</p>
                <p><strong>Description:</strong> {fee.description}</p>
                <p><strong>Date:</strong> {fee.date}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No fees added yet.</p>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: 800,
    margin: "0 auto",
    padding: 20,
    display: "grid",
    gap: 20,
    gridTemplateColumns: "1fr", // Default to show one container in small screens
    // Media query for medium screens
    "@media (min-width: 768px)": {
      gridTemplateColumns: "1fr", // Show one container in medium screens
    },
    // Media query for large screens
    "@media (min-width: 1024px)": {
      gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))", // Show 2 containers in a row in large screens
    },
  },
  section: {
    border: "1px solid #ccc",
    borderRadius: 8,
    padding: 15,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 400,
    margin: "auto",
  },
  input: {
    padding: 10,
    marginBottom: 10,
    borderRadius: 4,
    border: "1px solid #ccc",
    fontSize: "1rem",
    width: "100%",
  },
  button: {
    padding: 10,
    borderRadius: 4,
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    cursor: "pointer",
    fontSize: "1rem",
    marginTop: 10,
  },
  list: {
    listStyle: "none",
    padding: 0,
  },
  listItem: {
    border: "1px solid #ccc",
    borderRadius: 4,
    marginBottom: 10,
    padding: 10,
  },
};

export default UserFees;
