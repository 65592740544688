import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { db } from "../firebase"; // Adjust the path as per your project structure
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { useParams } from "react-router-dom";
import Preloader from "../components/Preloader"; // Adjust the path as per your project structure

const FeesUser = () => {
  const { userId } = useParams(); // Get user ID from URL
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [fees, setFees] = useState([]);
  const [courses, setCourses] = useState([]);
  const [newCourseName, setNewCourseName] = useState("");
  const [newCourseFees, setNewCourseFees] = useState("");
  const [courseError, setCourseError] = useState("");

  // Fetch the existing fees and courses for the user
  useEffect(() => {
    const fetchFeesAndCourses = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "feesstudent", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setFees(data.fees || []);
          setCourses(data.courses || []);
        }
      } catch (err) {
        setError("Error fetching data.");
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchFeesAndCourses();
  }, [userId]);

  const handleSubmitFee = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const feeData = {
        amount: parseFloat(amount),
        description,
        date,
      };

      const docRef = doc(db, "feesstudent", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, {
          fees: arrayUnion(feeData)
        });
      } else {
        await setDoc(docRef, {
          userId,
          fees: [feeData],
          courses: [] // Initialize courses as an empty array
        });
      }
      setFees((prevFees) => [...prevFees, feeData]);
      setAmount("");
      setDescription("");
      setDate("");
    } catch (err) {
      setError("Error adding fee.");
      console.error("Error adding fee:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitCourse = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const courseData = {
        courseName: newCourseName,
        courseFees: parseFloat(newCourseFees),
      };

      const docRef = doc(db, "feesstudent", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, {
          courses: arrayUnion(courseData)
        });
      } else {
        await setDoc(docRef, {
          userId,
          fees: [],
          courses: [courseData]
        });
      }
      setCourses((prevCourses) => [...prevCourses, courseData]);
      setNewCourseName("");
      setNewCourseFees("");
    } catch (err) {
      setCourseError("Error adding course.");
      console.error("Error adding course:", err);
    } finally {
      setLoading(false);
    }
  };

  // Calculate total fees and remaining fees after deducting total course fees
  const totalFee = fees.reduce((sum, fee) => sum + fee.amount, 0);
  const remainingFees = totalFee - courses.reduce((sum, course) => sum + course.courseFees, 0);

  if (loading) {
    return <Preloader />;
  }

  return (
    <Container>
      <Title>Manage Fees and Courses for User ID: {userId}</Title>
      <ContentWrapper>
        <FormWrapper>
          <Form onSubmit={handleSubmitFee}>
            <Input
              type="number"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
            <Input
              type="text"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
            <Input
              type="date"
              placeholder="Date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {remainingFees === 0 ? (
              <message>All fees paid.</message>
            ) : (
              <Button type="submit">Add Fee</Button>
            )}
          </Form>
          <Form onSubmit={handleSubmitCourse}>
            <Input
              type="text"
              placeholder="Course Name"
              value={newCourseName}
              onChange={(e) => setNewCourseName(e.target.value)}
              required
            />
            <Input
              type="number"
              placeholder="Course Fees"
              value={newCourseFees}
              onChange={(e) => setNewCourseFees(e.target.value)}
              required
            />
            {courseError && <ErrorMessage>{courseError}</ErrorMessage>}
            <Button type="submit">Add Course</Button>
          </Form>
        </FormWrapper>
        <DetailsWrapper>
          <remainingFees>Remaining Fees: ₹{remainingFees.toFixed(2)}</remainingFees>
          <DetailsTitle>Fees List</DetailsTitle>
          <totalFee>Total Fee: ₹{totalFee.toFixed(2)}</totalFee>
          {fees.length === 0 ? (
            <NoDataMessage>No fees found for this user.</NoDataMessage>
          ) : (
            <FeesList>
              {fees.map((fee, index) => (
                <FeeItem key={index}>
                  <FeeDetails>
                    <strong>Amount:</strong> ₹{fee.amount}
                  </FeeDetails>
                  <FeeDetails>
                    <strong>Description:</strong> {fee.description}
                  </FeeDetails>
                  <FeeDetails>
                    <strong>Date:</strong> {fee.date}
                  </FeeDetails>
                </FeeItem>
              ))}
            </FeesList>
          )}
          <CoursesWrapper>
            <coursesTitle>Courses for User ID: {userId}</coursesTitle>
            {courses.length === 0 ? (
              <NoDataMessage>No courses found for this user.</NoDataMessage>
            ) : (
              <CoursesList>
                {courses.map((course, index) => (
                  <CourseItem key={index}>
                    <CourseDetails>
                      <strong>Course Name:</strong> {course.courseName}
                    </CourseDetails>
                    <CourseDetails>
                      <strong>Course Fees:</strong> ₹{course.courseFees.toFixed(2)}
                    </CourseDetails>
                  </CourseItem>
                ))}
              </CoursesList>
            )}
          </CoursesWrapper>
        </DetailsWrapper>
      </ContentWrapper>
    </Container>
  );
};

// Styled components
const Container = styled.div`
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FormWrapper = styled.div`
  flex: 1;
  margin-right: 20px;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const DetailsWrapper = styled.div`
  flex: 1;
  margin-left: 20px;

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

const DetailsTitle = styled.h2`
  font-size: 20px;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const FeesList = styled.div`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const FeeItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }
`;

const FeeDetails = styled.p`
  margin: 5px 0;
`;

const NoDataMessage = styled.p`
  text-align: center;
  color: #777;
`;

const CoursesWrapper = styled.div`
  margin-top: 20px;
`;

const CoursesList = styled.div`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const CourseItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }
`;

const CourseDetails = styled.p`
  margin: 5px 0;
`;

export default FeesUser;
