import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { auth } from "./firebase"; // Import your Firebase auth instance
import Dashboard from "./Dashboard"; // Correct import path for Dashboard
import Courses from "./pages/Courses";
import Register from "./pages/Register";
import Slider from './pages/Slider';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Login from "./Login";
import Leader from "./pages/Leader"; // Import Leader component
import UserDetails from './pages/UserDetails'; // Adjust the path as per your project structure
import UserList from "./pages/UserList";
import UserCourse from "./pages/UserCourse"; // Adjust path as per your project structure
import FeesUser from "./pages/FeesUser"; // Adjust path as per your project structure
import UserCertificate from "./pages/UserCertificate"; // Adjust path as per your project structure
import AddGallery from "./pages/Gallery";
import CareerDashboard from "./pages/Career";
import SocialMediaPage from "./pages/SocialMedia";
import TestimonialsDashboard from "./pages/Testimonials";
import CertificatesDashboard from "./pages/Certificate";
import UserFullDetails from "./pages/UserFulldetails"; // Correct import path for UserFullDetails
import ArrayPage from "./ArrayPage";
import UserFees from "./pages/UserFees";
import RegisterData from "./pages/RegisterUser";
import EditUser from "./pages/EditUser";

const NotFound = () => {
  return <h1>404 Not Found</h1>;
};

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Firebase listener to check if user is logged in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while checking auth
  }

  return (
    <Router>
      {user && <Header />} {/* Show header only if user is authenticated */}
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, padding: "20px" }}>
          <Routes>
            {/* Public Route */}
            <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />

            {/* Protected Routes */}
            <Route path="/" element={user ? <Dashboard /> : <Navigate to="/login" />} />
            <Route path="/courses" element={user ? <Courses /> : <Navigate to="/login" />} />
            <Route path="/register" element={user ? <Register /> : <Navigate to="/login" />} />

            <Route path="/registeruse" element={user ? <RegisterData/> : <Navigate to="/login" />} />
            <Route path="/edituser/:userid" element={<EditUser />} />
            

            <Route path="/slider" element={user ? <Slider /> : <Navigate to="/login" />} /> {/* New Slider Page Route */}
            <Route path="/leader" element={user ? <Leader /> : <Navigate to="/login" />} />
            <Route path="/testimonials" element={user ? <TestimonialsDashboard /> : <Navigate to="/login" />} />
            <Route path="/certificates" element={user ? <CertificatesDashboard /> : <Navigate to="/login" />} />
            <Route path="/array" element={user ? <ArrayPage /> : <Navigate to="/login" />} />

            <Route path="/userlist" element={user ? <UserList /> : <Navigate to="/login" />} />
            <Route path="/gallery" element={user ? <AddGallery /> : <Navigate to="/login" />} />
            <Route path="/career" element={user ? <CareerDashboard /> : <Navigate to="/login" />} />
            <Route path="/social" element={user ? <SocialMediaPage /> : <Navigate to="/login" />} />
            <Route path="/userdetails/:userid" element={user ? <UserDetails /> : <Navigate to="/login" />} />
            <Route path="/usercourse/:userid" element={user ? <UserCourse /> : <Navigate to="/login" />} />
            <Route path="/feesuser/:userid" element={user ? <FeesUser /> : <Navigate to="/login" />} />
            <Route path="/userfees/:userid" element={user ? <UserFees /> : <Navigate to="/login" />} />
            
            <Route path="/usercertificate/:userid" element={user ? <UserCertificate /> : <Navigate to="/login" />} />

            <Route path="/userfulldetails/:userid" element={user ? <UserFullDetails /> : <Navigate to="/login" />} />

            {/* 404 Route - Handle undefined routes */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
      {user && <Footer />} {/* Show footer only if user is authenticated */}
    </Router>
  );
};

export default App;
