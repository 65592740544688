import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import styled from 'styled-components';
import Preloader from '../components/Preloader'; // Import the Preloader component

const CertificatesDashboard = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(''); // Optional date field
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [loading, setLoading] = useState(false); // Add a loading state

  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    // Fetch certificates from Firestore
    const fetchCertificates = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'certificates'));
        const certificatesList = [];
        querySnapshot.forEach((doc) => {
          certificatesList.push({ id: doc.id, ...doc.data() });
        });
        setCertificates(certificatesList);
      } catch (error) {
        console.error('Error fetching certificates:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCertificates();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true

    try {
      if (selectedCertificate) {
        // Edit existing certificate
        if (image) {
          // If new image is selected, upload it
          await uploadImageAndSaveCertificate();
        } else {
          // If no new image, update with existing image URL
          await updateDoc(doc(db, 'certificates', selectedCertificate.id), {
            name,
            description,
            date: date || 'N/A', // Save 'N/A' if no date provided
          });
          const updatedCertificates = certificates.map(cert =>
            cert.id === selectedCertificate.id ? { ...cert, name, description, date: date || 'N/A' } : cert
          );
          setCertificates(updatedCertificates);
          alert('Certificate updated successfully!');
        }
      } else {
        // Add new certificate
        if (image) {
          // If new image is selected, upload it
          await uploadImageAndSaveCertificate();
        } else {
          // Use default image URL if no image is selected
          const defaultImageUrl = 'default_image_url'; // Replace with your default image URL
          const newCertificate = {
            name,
            description,
            date: date || 'N/A', // Save 'N/A' if no date provided
            imageUrl: defaultImageUrl,
          };
          const docRef = await addDoc(collection(db, 'certificates'), newCertificate);
          setCertificates([...certificates, { id: docRef.id, ...newCertificate }]);
          alert('Certificate added successfully!');
        }
      }
      resetForm();
    } catch (error) {
      console.error('Error adding/updating document:', error);
      alert('Failed to add/update certificate. Please try again.');
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const uploadImageAndSaveCertificate = () => {
    setLoading(true); // Set loading to true
    const imageRef = ref(storage, `certificates/${image.name}`);
    const uploadTask = uploadBytesResumable(imageRef, image);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error('Upload failed:', error);
        alert('Upload failed. Please try again.');
        setLoading(false); // Set loading to false
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        try {
          if (selectedCertificate) {
            // Update existing certificate with new image URL
            await updateDoc(doc(db, 'certificates', selectedCertificate.id), {
              name,
              description,
              date: date || 'N/A', // Save 'N/A' if no date provided
              imageUrl: downloadURL,
            });
            const updatedCertificates = certificates.map(cert =>
              cert.id === selectedCertificate.id ? { ...cert, name, description, date: date || 'N/A', imageUrl: downloadURL } : cert
            );
            setCertificates(updatedCertificates);
            alert('Certificate updated successfully!');
          } else {
            // Add new certificate with image URL
            const newCertificate = {
              name,
              description,
              date: date || 'N/A', // Save 'N/A' if no date provided
              imageUrl: downloadURL,
            };
            const docRef = await addDoc(collection(db, 'certificates'), newCertificate);
            setCertificates([...certificates, { id: docRef.id, ...newCertificate }]);
            alert('Certificate added successfully!');
          }
          resetForm();
        } catch (error) {
          console.error('Error adding/updating document:', error);
          alert('Failed to add/update certificate. Please try again.');
        } finally {
          setLoading(false); // Set loading to false
        }
      }
    );
  };

  const handleEdit = (cert) => {
    setSelectedCertificate(cert);
    setName(cert.name);
    setDescription(cert.description);
    setDate(cert.date !== 'N/A' ? cert.date : ''); // If 'N/A', clear the date input
  };

  const handleDelete = async (certId) => {
    if (window.confirm('Are you sure you want to delete this certificate?')) {
      setLoading(true); // Set loading to true
      try {
        await deleteDoc(doc(db, 'certificates', certId));
        setCertificates(certificates.filter(cert => cert.id !== certId));
        alert('Certificate deleted successfully!');
      } catch (error) {
        console.error('Error deleting certificate:', error);
        alert('Failed to delete certificate. Please try again.');
      } finally {
        setLoading(false); // Set loading to false
      }
    }
  };

  const resetForm = () => {
    setName('');
    setDescription('');
    setDate(''); // Reset the date
    setImage(null);
    setProgress(0);
    setSelectedCertificate(null);
  };

  return (
    <>
      <PreloaderComponent loading={loading} />
      <DashboardContainer>
        <AddCertificateContainer>
          <h2>{selectedCertificate ? 'Edit Certificate' : 'Add New Certificate'}</h2>
          <FormContainer onSubmit={handleSubmit}>
            <FormInput
              type="text"
              placeholder="Certificate Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <FormTextArea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
            <FormInput
              type="date"
              placeholder="Date of Certification"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
            <FormInput type="file" accept="image/*" onChange={(e) => setImage(e.target.files[0])} />
            <ProgressBar progress={progress} />
            <SubmitButton type="submit">{selectedCertificate ? 'Update Certificate' : 'Add Certificate'}</SubmitButton>
          </FormContainer>
        </AddCertificateContainer>

        <CertificatesContainer>
          <h2>Existing Certificates</h2>
          <CertificatesList>
            {certificates.length > 0 ? (
              certificates.map((cert) => (
                <CertificateCard key={cert.id}>
                  <CertificateImage src={cert.imageUrl} alt={cert.name} />
                  <CertificateInfo>
                    <h3>{cert.name}</h3>
                    <p>{cert.description}</p>
                    {cert.date && <p><strong>Date:</strong> {cert.date}</p>}
                    <ButtonContainer>
                      <EditButton onClick={() => handleEdit(cert)}>Edit</EditButton>
                      <DeleteButton onClick={() => handleDelete(cert.id)}>Delete</DeleteButton>
                    </ButtonContainer>
                  </CertificateInfo>
                </CertificateCard>
              ))
            ) : (
              <p>No certificates available.</p>
            )}
          </CertificatesList>
        </CertificatesContainer>
      </DashboardContainer>
    </>
  );
};

// Preloader Component
const PreloaderComponent = ({ loading }) => {
  return loading ? <Preloader /> : null;
};

// Styled Components
const DashboardContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  flex-direction: column;
`;

const AddCertificateContainer = styled.div`
  margin-bottom: 40px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const FormTextArea = styled.textarea`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100px;
  resize: none;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const ProgressBar = styled.div`
  height: 20px;
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  &::after {
    content: '';
    display: block;
    height: 100%;
    width: ${(props) => props.progress}%;
    background-color: #4CAF50;
  }
`;

const CertificatesContainer = styled.div`
  margin-top: 40px;
`;

const CertificatesList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CertificateCard = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
  width: 300px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const CertificateImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CertificateInfo = styled.div`
  padding: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const EditButton = styled.button`
  background-color: #008CBA;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #007B9E;
  }
`;

const DeleteButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e03b2e;
  }
`;

export default CertificatesDashboard;
