import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "./firebase"; // Adjust the path as per your Firebase setup
import { collection, getDocs } from "firebase/firestore";
import styled from "styled-components";
import Preloader from "./components/Preloader"; // Adjust the path as per your project structure

// Styled components
const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f7fa;
  min-height: 100vh;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 20px;
`;

const Button = styled.button`
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  border: 2px solid teal;
  padding: 30px 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  width: 220px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  }
`;

const CardCount = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: #4caf50;
`;

const CardTitle = styled.div`
  font-size: 1.2rem;
  color: #555;
  margin: 10px 0;
`;

const CardButton = styled(Button)`
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  &:hover {
    background-color: #0056b3;
  }
`;

const Dashboard = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [studentCount, setStudentCount] = useState(0);
  const [leaderCount, setLeaderCount] = useState(0);
  const [courseCount, setCourseCount] = useState(0);
  const [sliderCount, setSliderCount] = useState(0);
  const [testimonialCount, setTestimonialCount] = useState(0);
  const [galleryCount, setGalleryCount] = useState(0);
  const [careerCount, setCareerCount] = useState(0);
  const [socialMediaCount, setSocialMediaCount] = useState(0);
  const [loading, setLoading] = useState(true); // State for loading indicator
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        fetchData();
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchData = async () => {
    try {
      const studentsSnapshot = await getDocs(collection(db, 'students'));
      const leadersSnapshot = await getDocs(collection(db, 'leaders'));
      const coursesSnapshot = await getDocs(collection(db, 'courses'));
      const sliderSnapshot = await getDocs(collection(db, 'sliderItems'));
      const testimonialsSnapshot = await getDocs(collection(db, 'testimonials'));
      const gallerySnapshot = await getDocs(collection(db, 'gallery'));
      const careerSnapshot = await getDocs(collection(db, 'careerItems'));
      const socialMediaSnapshot = await getDocs(collection(db, 'socialMediaItems'));

      setStudentCount(studentsSnapshot.size);
      setLeaderCount(leadersSnapshot.size);
      setCourseCount(coursesSnapshot.size);
      setSliderCount(sliderSnapshot.size);
      setTestimonialCount(testimonialsSnapshot.size);
      setGalleryCount(gallerySnapshot.size);
      setCareerCount(careerSnapshot.size);
      setSocialMediaCount(socialMediaSnapshot.size);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut(); // Firebase sign out method
      window.location.href = "/"; // Redirect using window.location
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <DashboardContainer>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Title>Welcome to Admin Dashboard</Title>
          {currentUser && (
            <CardContainer>
              <Card>
                <CardCount>{studentCount}</CardCount>
                <CardTitle>Students</CardTitle>
                <CardButton onClick={() => navigate("/register")}>View All</CardButton>
              </Card>
              <Card>
                <CardCount>{leaderCount}</CardCount>
                <CardTitle>Leaders</CardTitle>
                <CardButton onClick={() => navigate("/leader")}>View All</CardButton>
              </Card>
              <Card>
                <CardCount>{courseCount}</CardCount>
                <CardTitle>Courses</CardTitle>
                <CardButton onClick={() => navigate("/courses")}>View All</CardButton>
              </Card>
              <Card>
                <CardCount>{sliderCount}</CardCount>
                <CardTitle>Slider Items</CardTitle>
                <CardButton onClick={() => navigate("/slider")}>View All</CardButton>
              </Card>
              <Card>
                <CardCount>{testimonialCount}</CardCount>
                <CardTitle>Testimonials</CardTitle>
                <CardButton onClick={() => navigate("/testimonials")}>View All</CardButton>
              </Card>
              <Card>
                <CardCount>{galleryCount}</CardCount>
                <CardTitle>Gallery</CardTitle>
                <CardButton onClick={() => navigate("/gallery")}>View All</CardButton>
              </Card>
              <Card>
                <CardCount>{careerCount}</CardCount>
                <CardTitle>Career</CardTitle>
                <CardButton onClick={() => navigate("/career")}>View All</CardButton>
              </Card>
              <Card>
                <CardCount>{socialMediaCount}</CardCount>
                <CardTitle>Social Media</CardTitle>
                <CardButton onClick={() => navigate("/social")}>View All</CardButton>
              </Card>
            </CardContainer>
          )}
        </>
      )}
      <Button onClick={handleLogout}>Logout</Button>
    </DashboardContainer>
  );
};

export default Dashboard;
