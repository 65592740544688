import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getFirestore, collection, addDoc, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Preloader from './components/Preloader'; // Adjust the path as per your project structure

const ArrayPage = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [links, setLinks] = useState(''); // New state for comma-separated links
  const [imageFiles, setImageFiles] = useState([]); // State for uploaded image files
  const [imageNames, setImageNames] = useState([]); // State for image names
  const [careerItems, setCareerItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);

  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    const fetchCareerItems = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'arrayItems'));
        const itemsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCareerItems(itemsList);
      } catch (error) {
        console.error('Error fetching career items:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCareerItems();
  }, [db]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const linksArray = links.split(',').map(link => link.trim());

    // Upload images to Firebase Storage and get their URLs
    const uploadedImageURLs = await Promise.all(imageFiles.map(async (file, index) => {
      const imageRef = ref(storage, `careerImages/${file.name}`);
      await uploadBytes(imageRef, file);
      const downloadURL = await getDownloadURL(imageRef);
      return { url: downloadURL, name: imageNames[index] };
    }));

    const careerData = {
      title,
      description,
      links: linksArray,
      images: uploadedImageURLs, // Store image URLs and names
    };

    if (selectedItem) {
      try {
        await updateDoc(doc(db, 'arrayItems', selectedItem.id), careerData);
        const updatedItems = careerItems.map(item =>
          item.id === selectedItem.id ? { ...item, ...careerData } : item
        );
        setCareerItems(updatedItems);
        alert('Career item updated successfully!');
        resetForm();
      } catch (error) {
        console.error('Error updating career item:', error);
        alert('Failed to update career item. Please try again.');
      }
    } else {
      try {
        const docRef = await addDoc(collection(db, 'arrayItems'), careerData);
        setCareerItems([...careerItems, { id: docRef.id, ...careerData }]);
        alert('Career item added successfully!');
        resetForm();
      } catch (error) {
        console.error('Error adding career item:', error);
        alert('Failed to add career item. Please try again.');
      }
    }

    setLoading(false);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setTitle(item.title);
    setDescription(item.description);
    setLinks(item.links ? item.links.join(', ') : '');
    setImageFiles([]); // Reset image files for edit
    setImageNames(item.images ? item.images.map(image => image.name).join(', ') : '');
  };

  const handleDelete = async (itemId) => {
    if (window.confirm('Are you sure you want to delete this career item?')) {
      try {
        await deleteDoc(doc(db, 'arrayItems', itemId));
        setCareerItems(careerItems.filter(item => item.id !== itemId));
        alert('Career item deleted successfully!');
      } catch (error) {
        console.error('Error deleting career item:', error);
        alert('Failed to delete career item. Please try again.');
      }
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(files);
  };

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setLinks('');
    setImageFiles([]);
    setImageNames('');
    setSelectedItem(null);
  };

  return (
    <DashboardContainer>
      {loading && <Preloader />}

      <AddItemContainer>
        <h2>{selectedItem ? 'Edit Career Item' : 'Add New Career Item'}</h2>
        <FormContainer onSubmit={handleSubmit}>
          <FormInput
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <FormTextArea
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <FormInput
            type="text"
            placeholder="Links (comma-separated)"
            value={links}
            onChange={(e) => setLinks(e.target.value)}
            required
          />
          <FormInput
            type="file"
            multiple
            onChange={handleImageChange}
          />
          <FormInput
            type="text"
            placeholder="Image Names (comma-separated)"
            value={imageNames}
            onChange={(e) => setImageNames(e.target.value)}
            required
          />
          <SubmitButton type="submit">{selectedItem ? 'Update Item' : 'Add Item'}</SubmitButton>
        </FormContainer>
      </AddItemContainer>

      <CareerItemsContainer>
        <h2>Career Items</h2>
        <ItemsList>
          {careerItems.length > 0 ? (
            careerItems.map((item) => (
              <ItemCard key={item.id}>
                <ItemInfo>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                  {item.images && item.images.length > 0 ? (
                    item.images.map((image, index) => (
                      <div key={index}>
                        <img src={image.url} alt={image.name} style={{ width: '100px', marginRight: '10px' }} />
                        <p>{image.name}</p>
                      </div>
                    ))
                  ) : (
                    <p>No images available</p>
                  )}
                  {item.links && item.links.length > 0 ? (
                    item.links.map((link, index) => (
                      <p key={index}>
                        <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
                      </p>
                    ))
                  ) : (
                    <p>No links available</p>
                  )}
                  <ButtonContainer>
                    <EditButton onClick={() => handleEdit(item)}>Edit</EditButton>
                    <DeleteButton onClick={() => handleDelete(item.id)}>Delete</DeleteButton>
                  </ButtonContainer>
                </ItemInfo>
              </ItemCard>
            ))
          ) : (
            <p>No career items available.</p>
          )}
        </ItemsList>
      </CareerItemsContainer>
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const AddItemContainer = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const FormContainer = styled.form`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
`;

const FormTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
  height: 100px;
  resize: none;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: teal;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: darkcyan;
  }
`;

const CareerItemsContainer = styled.div`
  flex: 1;
  margin-top: 20px;
`;

const ItemsList = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

const ItemCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ItemInfo = styled.div`
  flex: 1;

  h3 {
    margin: 0 0 5px;
    font-size: 1.2rem;
  }

  p {
    margin: 0 0 10px;
    color: #555;
  }

  img {
    width: 100px;
    margin-right: 10px;
    border-radius: 4px;
  }

  a {
    color: #3498db;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
`;

const EditButton = styled.button`
  padding: 8px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-color: #2980b9;
  }
`;

const DeleteButton = styled.button`
  padding: 8px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #c0392b;
  }
`;

export default ArrayPage;
