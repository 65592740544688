import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  FaHome,
  FaBars,
  FaBook,
  FaSignInAlt,
  FaUserPlus,
  FaTrophy,
  FaCommentDots,
  FaImages,
  FaSuitcase,
  FaLink,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { auth } from "../firebase"; // Import Firebase auth instance
import { signOut } from "firebase/auth";

const HeaderContainer = styled.header`
  background-color: teal;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100;
  align-items: center;
  color: white;
  z-index: 1000;
`;

const Title = styled.h1`
  margin: 0;
`;

const Nav = styled.nav`
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }

  ul {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
  }

  a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;

    &.active {
      color: #ffeb3b;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

const MenuIcon = styled.div`
  font-size: 1.5em;
  cursor: pointer;

  @media (min-width: 768px) {
    display: none;
  }
`;

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  width: 250px;
  height: 100%;
  background-color: teal;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: left 0.3s ease;
  z-index: 999;
`;

const SidebarHeader = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #f0f0f0;
`;

const SidebarLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;

  &.active {
    color: #ffeb3b;
  }

  &:hover {
    color: #007bff;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [user, setUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const handleLogout = async () => {
    if (window.confirm("Are you sure you want to logout?")) {
      try {
        await signOut(auth);
        setUser(null);
        console.log("Logged out successfully");
      } catch (error) {
        console.error("Logout error:", error);
      }
    }
  };

  const navLinks = [
    { path: "/", label: "Dashboard", icon: <FaHome /> },
    { path: "/register", label: "Register", icon: <FaUserPlus /> },
    { path: "/courses", label: "Courses", icon: <FaBook /> },
    { path: "/leader", label: "Leader", icon: <FaTrophy /> },
    { path: "/testimonials", label: "Testimonials", icon: <FaCommentDots /> },
    { path: "/slider", label: "Slider", icon: <FaImages /> },
    { path: "/gallery", label: "Gallery", icon: <FaImages /> },
    { path: "/career", label: "Career", icon: <FaSuitcase /> },
    { path: "/social", label: "Social Media", icon: <FaLink /> },
    {
      path: "/login",
      label: user ? "Logout" : "Login",
      icon: <FaSignInAlt />,
      action: user ? handleLogout : null,
    },
  ];

  return (
    <>
      <HeaderContainer>
        <Title>Admin Sreef</Title>
        <Nav>
          <ul>
            {navLinks.map(({ path, label, icon, action }) => (
              <li key={path}>
                {action ? (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a onClick={action} style={{ cursor: "pointer" }}>
                    {icon} {label}
                  </a>
                ) : (
                  <Link
                    to={path}
                    onClick={closeSidebar}
                    className={location.pathname === path ? "active" : ""}
                  >
                    {icon} {label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </Nav>
        <MenuIcon onClick={toggleSidebar}>
          <FaBars />
        </MenuIcon>
      </HeaderContainer>
      <SidebarContainer isOpen={sidebarOpen}>
        <SidebarHeader>Sreef</SidebarHeader>
        {navLinks.map(({ path, label, icon, action }) => (
          <SidebarLink
            key={path}
            to={path}
            onClick={action ? (e) => { e.preventDefault(); action(); } : closeSidebar}
            className={location.pathname === path ? "active" : ""}
          >
            {icon} {label}
          </SidebarLink>
        ))}
      </SidebarContainer>
      <Overlay isOpen={sidebarOpen} onClick={toggleSidebar} />
    </>
  );
};

export default Header;
