import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getStorage, ref, listAll, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { useNavigate, useLocation } from "react-router-dom";
import Preloader from "../components/Preloader"; // Ensure this is correctly imported

const AdminGalleryPage = () => {
  const [folders, setFolders] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [newFolderName, setNewFolderName] = useState("");
  const [newFolderImage, setNewFolderImage] = useState(null);
  const [newImages, setNewImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const storage = getStorage(); // Initialize Firebase Storage

  useEffect(() => {
    fetchFolders();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const folderName = query.get("folder");
    if (folderName) {
      fetchImages(folderName);
    }
  }, [location.search]);

  // Fetch all folders
  const fetchFolders = async () => {
    setLoading(true);
    try {
      const storageRef = ref(storage, "images");
      const folderList = await listAll(storageRef);
      setFolders(folderList.prefixes);
    } catch (error) {
      console.error("Error fetching folders:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch images of a selected folder
  const fetchImages = async (folderName) => {
    setLoading(true);
    try {
      const folderRef = ref(storage, `images/${folderName}`);
      const imageList = await listAll(folderRef);
      const imageUrls = await Promise.all(
        imageList.items.map((itemRef) => getDownloadURL(itemRef))
      );
      setImages(imageUrls);
      setSelectedFolder(folderName);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle folder creation with an initial image
  const handleAddFolder = async () => {
    if (!newFolderName || !newFolderImage) {
      alert("Please enter a folder name and upload an image.");
      return;
    }

    setLoading(true);
    try {
      const folderRef = ref(storage, `images/${newFolderName}/${newFolderImage.name}`);
      await uploadBytes(folderRef, newFolderImage);
      setNewFolderName("");
      setNewFolderImage(null);
      fetchFolders(); // Refresh folders list
    } catch (error) {
      console.error("Error adding folder:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle image deletion with confirmation
  const handleDeleteImage = async (imageUrl) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this image?");
    if (!confirmDelete) return;

    setLoading(true);
    try {
      // Extract the storage path from the image URL
      const imageRefPath = imageUrl.split('/o/')[1].split('?')[0].replace(/%2F/g, '/');
      const imageRef = ref(storage, decodeURIComponent(imageRefPath));

      await deleteObject(imageRef);
      setImages((prevImages) => prevImages.filter((img) => img !== imageUrl));
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Error deleting image. Please check the console for more details.");
    } finally {
      setLoading(false);
    }
  };

  // Handle multiple image addition to selected folder
  const handleAddImages = async () => {
    if (!selectedFolder || newImages.length === 0) {
      alert("Please select a folder and upload at least one image.");
      return;
    }

    setLoading(true);
    try {
      await Promise.all(newImages.map(async (image) => {
        const imageRef = ref(storage, `images/${selectedFolder}/${image.name}`);
        await uploadBytes(imageRef, image);
      }));
      fetchImages(selectedFolder); // Refresh images list
      setNewImages([]);
    } catch (error) {
      console.error("Error adding images:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileInputChange = (event, setImage) => {
    const files = Array.from(event.target.files);
    setImage(files.length === 1 ? files[0] : files);
  };

  const handleFolderClick = (folderName) => {
    navigate(`?folder=${encodeURIComponent(folderName)}`);
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <Container>
      <FoldersContainer>
        <Title>Gallery Folders</Title>
        <FolderList>
          {folders.map((folder) => (
            <FolderCard key={folder.name} onClick={() => handleFolderClick(folder.name)}>
              <FolderTitle>{folder.name}</FolderTitle>
            </FolderCard>
          ))}
        </FolderList>
        <AddFolderForm onSubmit={(e) => { e.preventDefault(); handleAddFolder(); }}>
          <Input
            type="text"
            placeholder="New Folder Name"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            required
          />
          <Input
            type="file"
            onChange={(e) => handleFileInputChange(e, setNewFolderImage)}
            required
          />
          <Button type="submit">Add Folder</Button>
        </AddFolderForm>
      </FoldersContainer>
      <ImagesContainer>
      <AddImageForm onSubmit={(e) => { e.preventDefault(); handleAddImages(); }}>
          <Input
            type="file"
            multiple
            onChange={(e) => handleFileInputChange(e, setNewImages)}
            required
          />
          <Button type="submit">Add Images</Button>
        </AddImageForm>
        <Title>Images in {selectedFolder}</Title>
        <ImagesList>
          {images.map((image, index) => (
            <ImageCard key={index}>
              <Image src={image} alt={`Image ${index}`} />
              <ImageActions>
                <Button onClick={() => handleDeleteImage(image)}>Delete</Button>
              </ImageActions>
            </ImageCard>
          ))}
        </ImagesList>
       
      </ImagesContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FoldersContainer = styled.div`
  flex: 1;
  border-right: 2px solid #ccc;
  padding-right: 20px;
`;

const ImagesContainer = styled.div`
  flex: 1;
  padding-left: 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
`;

const FolderList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
`;

const FolderCard = styled.div`
  border: 2px solid teal;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const FolderTitle = styled.h2`
  margin: 0;
  color: #333;
  font-size: 1.2rem;
`;

const AddFolderForm = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ImagesList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
`;

const ImageCard = styled.div`
  border: 2px solid teal;
  border-radius: 8px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const ImageActions = styled.div`
  padding: 5px;
  display: flex;
  justify-content: center;
`;

const AddImageForm = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: teal;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: darkcyan;
  }
`;

export default AdminGalleryPage;
