// UserCourse.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";
import Preloader from "../components/Preloader"; // Import Preloader component

const UserCourse = () => {
  const { userid } = useParams();
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [courseDetails, setCourseDetails] = useState({
    centerName: "",
    courseName: "",
    courseFees: "",
    examFees: "",
    university: "",
    enrollmentSession: "",
    centerHead: "",
    startDate: "",
    endDate: "",
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserCourses = async () => {
      try {
        const userRef = doc(db, "students", userid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setCourses(docSnap.data().courses || []);
        } else {
          setError("User not found");
        }
      } catch (error) {
        console.error("Error fetching user courses:", error);
        setError("Error fetching user courses");
      } finally {
        setLoading(false);
      }
    };

    fetchUserCourses();
  }, [userid]);

  const handleCourseChange = (e) => {
    const { name, value } = e.target;
    setCourseDetails((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddCourse = async (e) => {
    e.preventDefault();

    try {
      const courseData = {
        centerName: courseDetails.centerName,
        courseName: courseDetails.courseName,
        courseFees: parseFloat(courseDetails.courseFees),
        examFees: parseFloat(courseDetails.examFees),
        university: courseDetails.university,
        enrollmentSession: courseDetails.enrollmentSession,
        centerHead: courseDetails.centerHead,
        startDate: courseDetails.startDate,
        endDate: courseDetails.endDate,
      };

      const userRef = doc(db, "students", userid);
      await updateDoc(userRef, {
        courses: arrayUnion(courseData),
      });

      setCourses((prevCourses) => [...prevCourses, courseData]);

      setCourseDetails({
        centerName: "",
        courseName: "",
        courseFees: "",
        examFees: "",
        university: "",
        enrollmentSession: "",
        centerHead: "",
        startDate: "",
        endDate: "",
      });

      alert("Course added successfully!");
    } catch (error) {
      console.error("Error adding course:", error);
      setError("Error adding course");
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div style={styles.container}>
      <h2>User Courses</h2>
      {courses.length > 0 ? (
        <ul style={styles.courseList}>
          {courses.map((course, index) => (
            <li key={index} style={styles.courseItem}>
              <p><strong>Center Name:</strong> {course.centerName}</p>
              <p><strong>Course Name:</strong> {course.courseName}</p>
              <p><strong>Course Fees:</strong> {course.courseFees}</p>
              <p><strong>Exam Fees:</strong> {course.examFees}</p>
              <p><strong>University:</strong> {course.university}</p>
              <p><strong>Enrollment Session:</strong> {course.enrollmentSession}</p>
              <p><strong>Center Head:</strong> {course.centerHead}</p>
              <p><strong>Start Date:</strong> {course.startDate}</p>
              <p><strong>End Date:</strong> {course.endDate}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No courses found for this user.</p>
      )}

      {/* Add course form */}
      <h2>Add Course</h2>
      <form onSubmit={handleAddCourse} style={styles.form}>
        <input
          type="text"
          name="centerName"
          placeholder="Center Name"
          value={courseDetails.centerName}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="text"
          name="courseName"
          placeholder="Course Name"
          value={courseDetails.courseName}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="number"
          name="courseFees"
          placeholder="Course Fees"
          value={courseDetails.courseFees}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="number"
          name="examFees"
          placeholder="Exam Fees"
          value={courseDetails.examFees}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="text"
          name="university"
          placeholder="University"
          value={courseDetails.university}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="text"
          name="enrollmentSession"
          placeholder="Enrollment Session"
          value={courseDetails.enrollmentSession}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="text"
          name="centerHead"
          placeholder="Center Head"
          value={courseDetails.centerHead}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="date"
          name="startDate"
          placeholder="Start Date"
          value={courseDetails.startDate}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="date"
          name="endDate"
          placeholder="End Date"
          value={courseDetails.endDate}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <button type="submit" style={styles.button}>
          Add Course
        </button>
      </form>
    </div>
  );
};

// Inline CSS styles
const styles = {
  container: {
    padding: "20px",
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    color: "#333",
    backgroundColor: "#f8f9fa",
  },
  courseList: {
    listStyle: "none",
    padding: 0,
  },
  courseItem: {
    marginBottom: "20px",
    padding: "15px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "600px",
    margin: "auto",
    marginTop: "20px",
  },
  input: {
    padding: "12px",
    marginBottom: "12px",
    borderRadius: "6px",
    border: "1px solid #ccc",
    fontSize: "16px",
    width: "100%",
  },
  button: {
    padding: "12px",
    borderRadius: "6px",
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    cursor: "pointer",
    fontSize: "16px",
    marginTop: "10px",
    transition: "background-color 0.3s ease",
  },
  buttonHover: {
    backgroundColor: "#0056b3",
  },
};

export default UserCourse;
