import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import Preloader from "../components/Preloader";
import styled from "styled-components";

const UserFullDetails = () => {
  const { userid } = useParams();
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    imageUrl: "",
    courses: [],
    fees: [],
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(db, "students", userid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUserDetails({
            ...docSnap.data(),
            courses: docSnap.data().courses || [],
            fees: docSnap.data().fees || [],
          });
        } else {
          setError("User not found");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setError("Error fetching user details");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userid]);

  const handlePrint = () => {
    window.print();
  };

  const handleDownloadPDF = () => {
    // Logic to download details as a PDF
  };

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <PageContainer>
      <ProfileCard>
        <Title>User Details</Title>
        <UserImage
          src={userDetails.imageUrl || "path-to-default-user-image.jpg"}
          alt="User Profile"
        />
        <InfoBlock>
          <InfoLabel>Full Name</InfoLabel>
          <InfoSeparator>-</InfoSeparator>
          <InfoData>{userDetails.fullName}</InfoData>
        </InfoBlock>
        <InfoBlock>
          <InfoLabel>Email</InfoLabel>
          <InfoSeparator>-</InfoSeparator>
          <InfoData>{userDetails.email}</InfoData>
        </InfoBlock>
        <InfoBlock>
          <InfoLabel>Mobile Number</InfoLabel>
          <InfoSeparator>-</InfoSeparator>
          <InfoData>{userDetails.mobileNumber}</InfoData>
        </InfoBlock>

        <CourseContainer>
          <CourseTitle>Courses</CourseTitle>
          {userDetails.courses.length > 0 ? (
            userDetails.courses.map((course, index) => (
              <CourseDetails key={index}>
                <InfoBlock>
                  <InfoLabel>Center Name</InfoLabel>
                  <InfoSeparator>-</InfoSeparator>
                  <InfoData>{course.centerName}</InfoData>
                </InfoBlock>
                <InfoBlock>
                  <InfoLabel>Course Name</InfoLabel>
                  <InfoSeparator>-</InfoSeparator>
                  <InfoData>{course.courseName}</InfoData>
                </InfoBlock>
                <InfoBlock>
                  <InfoLabel>Course Fees</InfoLabel>
                  <InfoSeparator>-</InfoSeparator>
                  <InfoData>{course.courseFees}</InfoData>
                </InfoBlock>
                <InfoBlock>
                  <InfoLabel>University</InfoLabel>
                  <InfoSeparator>-</InfoSeparator>
                  <InfoData>{course.university}</InfoData>
                </InfoBlock>
                <InfoBlock>
                  <InfoLabel>Enrollment Session</InfoLabel>
                  <InfoSeparator>-</InfoSeparator>
                  <InfoData>{course.enrollmentSession}</InfoData>
                </InfoBlock>
                <InfoBlock>
                  <InfoLabel>Center Head</InfoLabel>
                  <InfoSeparator>-</InfoSeparator>
                  <InfoData>{course.centerHead}</InfoData>
                </InfoBlock>
                <InfoBlock>
                  <InfoLabel>Start Date</InfoLabel>
                  <InfoSeparator>-</InfoSeparator>
                  <InfoData>{course.startDate}</InfoData>
                </InfoBlock>
                <InfoBlock>
                  <InfoLabel>End Date</InfoLabel>
                  <InfoSeparator>-</InfoSeparator>
                  <InfoData>{course.endDate}</InfoData>
                </InfoBlock>
                {index < userDetails.courses.length - 1 && <Divider />}
              </CourseDetails>
            ))
          ) : (
            <NoDataMessage>No courses added yet.</NoDataMessage>
          )}
        </CourseContainer>

        <CourseContainer>
          <CourseTitle>Fees</CourseTitle>
          {userDetails.fees.length > 0 ? (
            userDetails.fees.map((fee, index) => (
              <CourseDetails key={index}>
                <InfoBlock>
                  <InfoLabel>Amount</InfoLabel>
                  <InfoSeparator>-</InfoSeparator>
                  <InfoData>{fee.amount}</InfoData>
                </InfoBlock>
                <InfoBlock>
                  <InfoLabel>Description</InfoLabel>
                  <InfoSeparator>-</InfoSeparator>
                  <InfoData>{fee.description}</InfoData>
                </InfoBlock>
                <InfoBlock>
                  <InfoLabel>Date</InfoLabel>
                  <InfoSeparator>-</InfoSeparator>
                  <InfoData>{fee.date}</InfoData>
                </InfoBlock>
                {index < userDetails.fees.length - 1 && <Divider />}
              </CourseDetails>
            ))
          ) : (
            <NoDataMessage>No fees added yet.</NoDataMessage>
          )}
        </CourseContainer>
      </ProfileCard>

      <ButtonContainer>
        <Button onClick={handlePrint}>Print</Button>
        <Button onClick={handleDownloadPDF}>Download PDF</Button>
      </ButtonContainer>
    </PageContainer>
  );
};

// Styled components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(to right, #f5f7fa, #c3cfe2);
  color: #333;
`;

const ProfileCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 210mm;
  width: 100%;
  margin: 20px 0;
  animation: fadeIn 0.6s ease-in-out;
  box-sizing: border-box;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media print {
    box-shadow: none;
    margin: 0;
    padding: 10mm;
    page-break-after: always;
  }
`;

const Title = styled.h2`
  text-align: center;
  color: #4a90e2;
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: bold;

  @media print {
    font-size: 1.5rem;
  }
`;

const InfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;

  @media print {
    padding: 5px 0;
  }
`;

const InfoLabel = styled.div`
  color: #888;
  font-size: 0.9rem;
  font-weight: 500;
  width: 30%;
  text-align: left;

  @media print {
    font-size: 0.8rem;
  }
`;

const InfoSeparator = styled.div`
  width: 10%;
  text-align: center;
  color: #888;

  @media print {
    font-size: 0.8rem;
  }
`;

const InfoData = styled.div`
  color: #333;
  font-size: 0.9rem;
  font-weight: 600;
  width: 60%;
  text-align: left;

  @media print {
    font-size: 0.8rem;
  }
`;

const CourseContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  @media print {
    box-shadow: none;
    margin-bottom: 10px;
    padding: 10px;
    page-break-inside: avoid;
  }
`;

const CourseTitle = styled.h3`
  color: #4a90e2;
  margin-bottom: 15px;
  font-size: 1.5rem;

  @media print {
    font-size: 1.2rem;
  }
`;

const CourseDetails = styled.div`
  @media print {
    page-break-inside: avoid;
  }
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;

  @media print {
    margin: 5px 0;
  }
`;

const UserImage = styled.img`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid black;

  @media print {
    width: 100px;
    height: 100px;
  }
`;

const NoDataMessage = styled.p`
  text-align: center;
  color: #666;
  margin-top: 20px;
  font-size: 1rem;

  @media print {
    margin-top: 10px;
    font-size: 0.9rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media print {
    display: none;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const Error = styled.div`
  color: red;
  text-align: center;
  margin-top: 20px;
`;

export default UserFullDetails;
