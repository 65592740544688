import React, { useState, useEffect } from "react";
import { auth, db, storage } from "../firebase";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { collection, setDoc, doc, query, orderBy, limit, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import Preloader from "../components/Preloader"; // Importing Preloader component

const RegisterUser = ({ fetchUsers }) => {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    fullName: "",
    mobileNumber: "",
    image: null,
    imageUrl: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lastUserDetails, setLastUserDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLastUserDetails = async () => {
      setLoading(true);
      try {
        const usersQuery = query(
          collection(db, "students"),
          orderBy("username", "desc"),
          limit(1)
        );
        const querySnapshot = await getDocs(usersQuery);
        if (!querySnapshot.empty) {
          const lastUser = querySnapshot.docs[0].data();
          setLastUserDetails(lastUser);
        } else {
          setLastUserDetails(null);
        }
      } catch (error) {
        console.error("Error fetching last user details:", error);
        setLastUserDetails(null);
      } finally {
        setLoading(false);
      }
    };

    fetchLastUserDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setUserData((prevData) => ({ ...prevData, image: file }));
  };

  const generateUsername = async () => {
    const prefix = 'SREEF';
    let nextId = 1001; // Default starting point

    try {
      const usersQuery = query(
        collection(db, "students"),
        orderBy("username", "desc"),
        limit(1)
      );
      const querySnapshot = await getDocs(usersQuery);
      if (!querySnapshot.empty) {
        const lastUser = querySnapshot.docs[0].data();
        const lastUserNumber = parseInt(lastUser.username.replace(prefix, ''));
        if (!isNaN(lastUserNumber)) {
          nextId = lastUserNumber + 1; // Increment by 1
        }
      }
    } catch (error) {
      console.error("Error fetching last user:", error);
    }

    return `${prefix}${nextId}`;
  };

  const handleRegisterUser = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      let imageUrl = userData.imageUrl;

      if (userData.image) {
        const imageRef = ref(storage, `users/${userData.image.name}`);
        await uploadBytes(imageRef, userData.image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const username = await generateUsername();

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        userData.email,
        userData.password
      );
      const uid = userCredential.user.uid;

      // Use setDoc to set the document ID as the user's UID
      await setDoc(doc(db, "students", uid), {
        uid: uid,
        username: username,
        fullName: userData.fullName,
        email: userData.email,
        mobileNumber: userData.mobileNumber,
        imageUrl: imageUrl,
      });

      await sendEmailVerification(auth.currentUser);

      alert("User created successfully! Verification email sent.");

      setUserData({
        email: "",
        password: "",
        fullName: "",
        mobileNumber: "",
        image: null,
        imageUrl: "",
      });
      fetchUsers();

      // Refresh the page
      window.location.reload();
    } catch (error) {
      setError(error.message);
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      {loading && <Preloader />}
      <div style={styles.mainContent}>
        <div style={styles.formContainer}>
          <h2>Register User</h2>
          {error && <p style={styles.error}>{error}</p>}
          <form onSubmit={handleRegisterUser} style={styles.form}>
            <input
              type="text"
              name="fullName"
              placeholder="Full Name"
              value={userData.fullName}
              onChange={handleChange}
              required
              style={styles.input}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={userData.email}
              onChange={handleChange}
              required
              style={styles.input}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={userData.password}
              onChange={handleChange}
              required
              style={styles.input}
            />
            <input
              type="tel"
              name="mobileNumber"
              placeholder="Mobile Number"
              value={userData.mobileNumber}
              onChange={handleChange}
              required
              style={styles.input}
            />
            <input
              type="file"
              name="image"
              onChange={handleImageChange}
              style={styles.input}
            />
            <button type="submit" style={styles.button} disabled={loading}>
              {loading ? "Creating User..." : "Create User"}
            </button>
          </form>
          <button onClick={() => navigate('/userlist')} style={styles.navButton}>
            Go to User List
          </button>
        </div>
        {lastUserDetails ? (
          <div style={styles.lastUserDetailsContainer}>
            <h3>Last Registered User:</h3>
            <p><strong>Username:</strong> {lastUserDetails.username}</p>
            <p><strong>Full Name:</strong> {lastUserDetails.fullName}</p>
            <p><strong>Email:</strong> {lastUserDetails.email}</p>
            <p><strong>Mobile Number:</strong> {lastUserDetails.mobileNumber}</p>
            <img src={lastUserDetails.imageUrl} alt="User" style={styles.userImage} />
          </div>
        ) : (
          <p style={styles.noUserText}>No previous user found or loading...</p>
        )}
      </div>
    </div>
  );
};

// Inline CSS styles
const styles = {
  container: {
    padding: "10px",
    fontFamily: "Arial, sans-serif",
    color: "#333",
  },
  mainContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  formContainer: {
    flex: 1,
    padding: "10px",
    minWidth: "300px",
    marginBottom: "20px",
  },
  error: {
    color: "red",
    marginBottom: "10px",
  },
  lastUserDetailsContainer: {
    flex: 1,
    marginLeft: "10px",
    backgroundColor: "#f8f9fa",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #dee2e6",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "300px",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    maxWidth: "500px",
  },
  input: {
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "1rem",
    width: "100%",
  },
  button: {
    padding: "10px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    cursor: "pointer",
    fontSize: "1rem",
  },
  navButton: {
    padding: "10px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#28a745",
    color: "#fff",
    cursor: "pointer",
    fontSize: "1rem",
    marginTop: "10px",
  },
  userImage: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    marginTop: "10px",
  },
  noUserText: {
    textAlign: "center",
    color: "#666",
  },
  '@media (max-width: 768px)': {
    mainContent: {
      flexDirection: "column",
      alignItems: "center",
    },
    formContainer: {
      marginBottom: "0",
      paddingRight: "0",
    },
    lastUserDetailsContainer: {
      marginLeft: "0",
    },
  },
};

export default RegisterUser;
