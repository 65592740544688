import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db, storage } from '../firebase'; // Adjust the path as per your project structure
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Preloader from '../components/Preloader'; // Adjust the path as per your project structure

const UserDetails = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [updateData, setUpdateData] = useState({
    fullName: '',
    mobileNumber: '',
    image: null,
    imageUrl: '',
    fatherName: '',
    motherName: '',
    dob: '',
    gender: '',
    category: '',
    address: '',
    aadharNumber: '',
    areaPincode: ''
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [updating, setUpdating] = useState(false); // Updating state

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'students', userId));
        if (userDoc.exists()) {
          setUser(userDoc.data());
          setUpdateData({
            fullName: userDoc.data().fullName,
            mobileNumber: userDoc.data().mobileNumber,
            imageUrl: userDoc.data().imageUrl,
            fatherName: userDoc.data().fatherName || '',
            motherName: userDoc.data().motherName || '',
            dob: userDoc.data().dob || '',
            gender: userDoc.data().gender || '',
            category: userDoc.data().category || '',
            address: userDoc.data().address || '',
            aadharNumber: userDoc.data().aadharNumber || '',
            areaPincode: userDoc.data().areaPincode || ''
          });
        } else {
          console.error('User not found');
          setError('User not found');
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        setError('Error fetching user data');
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchUser();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setUpdateData((prevData) => ({ ...prevData, image: file }));
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    setError(null);
    setUpdating(true); // Set updating to true

    try {
      let imageUrl = updateData.imageUrl;

      if (updateData.image) {
        const imageRef = ref(storage, `users/${updateData.image.name}`);
        await uploadBytes(imageRef, updateData.image);
        imageUrl = await getDownloadURL(imageRef);
      }

      await updateDoc(doc(db, 'students', userId), {
        fullName: updateData.fullName,
        mobileNumber: updateData.mobileNumber,
        imageUrl: imageUrl,
        fatherName: updateData.fatherName,
        motherName: updateData.motherName,
        dob: updateData.dob,
        gender: updateData.gender,
        category: updateData.category,
        address: updateData.address,
        aadharNumber: updateData.aadharNumber,
        areaPincode: updateData.areaPincode
      });

      alert('User data updated successfully!');
      
      // Fetch updated user data
      const updatedUserDoc = await getDoc(doc(db, 'students', userId));
      setUser(updatedUserDoc.data());
    } catch (error) {
      console.error('Error updating user:', error);
      setError('Error updating user data');
    } finally {
      setUpdating(false); // Set updating to false
    }
  };

  if (loading) {
    return <Preloader />; // Display preloader while loading
  }

  return (
    <div style={styles.container}>
      {error ? (
        <p style={styles.error}>{error}</p>
      ) : user ? (
        <div style={styles.splitContainer}>
          {/* Left side: Update Form */}
          <div style={styles.formContainer}>
            <h2 style={styles.heading}>Update User Details</h2>
            <form onSubmit={handleUpdateUser} style={styles.form}>
              <input
                type="text"
                name="fullName"
                placeholder="Full Name"
                defaultValue={user.fullName}
                onChange={handleChange}
                style={styles.input}
              />
              <input
                type="tel"
                name="mobileNumber"
                placeholder="Mobile Number"
                defaultValue={user.mobileNumber}
                onChange={handleChange}
                style={styles.input}
              />
              <input
                type="text"
                name="fatherName"
                placeholder="Father's Name"
                defaultValue={user.fatherName}
                onChange={handleChange}
                style={styles.input}
              />
              <input
                type="text"
                name="motherName"
                placeholder="Mother's Name"
                defaultValue={user.motherName}
                onChange={handleChange}
                style={styles.input}
              />
              <input
                type="date"
                name="dob"
                placeholder="Date of Birth"
                defaultValue={user.dob}
                onChange={handleChange}
                style={styles.input}
              />
              <select
                name="gender"
                defaultValue={user.gender}
                onChange={handleChange}
                style={styles.input}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
              <select
                name="category"
                defaultValue={user.category}
                onChange={handleChange}
                style={styles.input}
              >
                <option value="">Select Category</option>
                <option value="General">General</option>
                <option value="OBC">OBC</option>
                <option value="SC">SC</option>
                <option value="ST">ST</option>
                <option value="Other">Other</option>
              </select>
              <input
                type="text"
                name="aadharNumber"
                placeholder="Aadhar Number"
                defaultValue={user.aadharNumber}
                onChange={handleChange}
                style={styles.input}
              />
              <input
                type="text"
                name="areaPincode"
                placeholder="Area Pincode"
                defaultValue={user.areaPincode}
                onChange={handleChange}
                style={styles.input}
              />
              <textarea
                name="address"
                placeholder="Address"
                defaultValue={user.address}
                onChange={handleChange}
                style={{ ...styles.input, minHeight: '80px' }}
              />
              <input
                type="file"
                name="image"
                onChange={handleImageChange}
                style={styles.input}
              />
              <button type="submit" style={styles.button}>
                {updating ? <Preloader inline /> : 'Update User'}
              </button>
            </form>
          </div>

          {/* Right side: User Details */}
          <div style={styles.userDataContainer}>
            <h2 style={styles.heading}>User Details</h2>
            {user.imageUrl && (
              <img src={user.imageUrl} alt={user.fullName} style={styles.userImage} />
            )}
            <p><strong>Full Name:</strong> {user.fullName}</p>
            <p><strong>Username:</strong> {user.username}</p>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Mobile Number:</strong> {user.mobileNumber}</p>
            <p><strong>Father's Name:</strong> {user.fatherName}</p>
            <p><strong>Mother's Name:</strong> {user.motherName}</p>
            <p><strong>Date of Birth:</strong> {user.dob}</p>
            <p><strong>Gender:</strong> {user.gender}</p>
            <p><strong>Category:</strong> {user.category}</p>
            <p><strong>Address:</strong> {user.address}</p>
            <p><strong>Aadhar Number:</strong> {user.aadharNumber}</p>
            <p><strong>Area Pincode:</strong> {user.areaPincode}</p>
          </div>
        </div>
      ) : (
        <p>User not found.</p>
      )}
    </div>
  );
};

// Inline CSS styles
const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    boxSizing: 'border-box',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
  splitContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap', // Allow wrapping for responsive layout
  },
  formContainer: {
    flex: '1',
    minWidth: '45%',
    marginRight: '20px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
    padding: '15px',
  },
  userDataContainer: {
    flex: '1',
    minWidth: '45%',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    boxSizing: 'border-box',
    marginBottom: '20px',
  },
  heading: {
    fontSize: '1.5rem',
    marginBottom: '20px',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '1rem',
    boxSizing: 'border-box',
  },
  button: {
    padding: '10px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '1rem',
    boxSizing: 'border-box',
  },
  userImage: {
    width: '100px', // Adjusted size for better visibility
    height: '100px',
    marginBottom: '10px',
    objectFit: 'cover',
    borderRadius: '50%',
  },
  '@media (max-width: 768px)': {
    splitContainer: {
      flexDirection: 'column', // Stack in a column on smaller screens
      alignItems: 'center',
    },
    formContainer: {
      width: '100%', // Take full width on smaller screens
      marginRight: 0,
      marginBottom: '20px',
    },
    userDataContainer: {
      width: '100%', // Take full width on smaller screens
    },
  },
};


export default UserDetails;
