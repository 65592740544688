import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import styled from 'styled-components';
import Preloader from '../components/Preloader'; // Import the Preloader component

const LeadersDashboard = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [leaders, setLeaders] = useState([]);
  const [selectedLeader, setSelectedLeader] = useState(null);
  const [loading, setLoading] = useState(false); // Add a loading state

  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    // Fetch leaders from Firestore
    const fetchLeaders = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'leaders'));
        const leadersList = [];
        querySnapshot.forEach((doc) => {
          leadersList.push({ id: doc.id, ...doc.data() });
        });
        setLeaders(leadersList);
      } catch (error) {
        console.error('Error fetching leaders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaders();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true); // Set loading to true
    try {
      if (selectedLeader) {
        // Edit existing leader
        if (image) {
          // If new image is selected, upload it
          await uploadImageAndSaveLeader();
        } else {
          // If no new image, update with existing image URL
          await updateDoc(doc(db, 'leaders', selectedLeader.id), {
            name,
            description,
          });
          const updatedLeaders = leaders.map(leader =>
            leader.id === selectedLeader.id ? { ...leader, name, description } : leader
          );
          setLeaders(updatedLeaders);
          alert('Leader updated successfully!');
        }
      } else {
        // Add new leader
        if (image) {
          // If new image is selected, upload it
          await uploadImageAndSaveLeader();
        } else {
          // Use default image URL if no image is selected
          const defaultImageUrl = 'default_image_url'; // Replace with your default image URL
          const newLeader = {
            name,
            description,
            imageUrl: defaultImageUrl,
          };
          const docRef = await addDoc(collection(db, 'leaders'), newLeader);
          setLeaders([...leaders, { id: docRef.id, ...newLeader }]);
          alert('Leader added successfully!');
        }
      }
      resetForm();
    } catch (error) {
      console.error('Error adding/updating document:', error);
      alert('Failed to add/update leader. Please try again.');
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const uploadImageAndSaveLeader = () => {
    setLoading(true); // Set loading to true
    const imageRef = ref(storage, `leaders/${image.name}`);
    const uploadTask = uploadBytesResumable(imageRef, image);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error('Upload failed:', error);
        alert('Upload failed. Please try again.');
        setLoading(false); // Set loading to false
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        try {
          if (selectedLeader) {
            // Update existing leader with new image URL
            await updateDoc(doc(db, 'leaders', selectedLeader.id), {
              name,
              description,
              imageUrl: downloadURL,
            });
            const updatedLeaders = leaders.map(leader =>
              leader.id === selectedLeader.id ? { ...leader, name, description, imageUrl: downloadURL } : leader
            );
            setLeaders(updatedLeaders);
            alert('Leader updated successfully!');
          } else {
            // Add new leader with image URL
            const newLeader = {
              name,
              description,
              imageUrl: downloadURL,
            };
            const docRef = await addDoc(collection(db, 'leaders'), newLeader);
            setLeaders([...leaders, { id: docRef.id, ...newLeader }]);
            alert('Leader added successfully!');
          }
          resetForm();
        } catch (error) {
          console.error('Error adding/updating document:', error);
          alert('Failed to add/update leader. Please try again.');
        } finally {
          setLoading(false); // Set loading to false
        }
      }
    );
  };

  const handleEdit = (leader) => {
    setSelectedLeader(leader);
    setName(leader.name);
    setDescription(leader.description);
  };

  const handleDelete = async (leaderId) => {
    if (window.confirm('Are you sure you want to delete this leader?')) {
      setLoading(true); // Set loading to true
      try {
        await deleteDoc(doc(db, 'leaders', leaderId));
        setLeaders(leaders.filter(leader => leader.id !== leaderId));
        alert('Leader deleted successfully!');
      } catch (error) {
        console.error('Error deleting leader:', error);
        alert('Failed to delete leader. Please try again.');
      } finally {
        setLoading(false); // Set loading to false
      }
    }
  };

  const resetForm = () => {
    setName('');
    setDescription('');
    setImage(null);
    setProgress(0);
    setSelectedLeader(null);
  };

  return (
    <>
      <PreloaderComponent loading={loading} />
      <DashboardContainer>
        <AddLeaderContainer>
          <h2>{selectedLeader ? 'Edit Leader' : 'Add New Leader'}</h2>
          <FormContainer onSubmit={handleSubmit}>
            <FormInput
              type="text"
              placeholder="Leader Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <FormTextArea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
            <FormInput type="file" accept="image/*" onChange={(e) => setImage(e.target.files[0])} />
            <ProgressBar progress={progress} />
            <SubmitButton type="submit">{selectedLeader ? 'Update Leader' : 'Add Leader'}</SubmitButton>
          </FormContainer>
        </AddLeaderContainer>

        <LeadersContainer>
          <h2>Existing Leaders</h2>
          <LeadersList>
            {leaders.length > 0 ? (
              leaders.map((leader) => (
                <LeaderCard key={leader.id}>
                  <LeaderImage src={leader.imageUrl} alt={leader.name} />
                  <LeaderInfo>
                    <h3>{leader.name}</h3>
                    <p>{leader.description}</p>
                    <ButtonContainer>
                      <EditButton onClick={() => handleEdit(leader)}>Edit</EditButton>
                      <DeleteButton onClick={() => handleDelete(leader.id)}>Delete</DeleteButton>
                    </ButtonContainer>
                  </LeaderInfo>
                </LeaderCard>
              ))
            ) : (
              <p>No leaders available.</p>
            )}
          </LeadersList>
        </LeadersContainer>
      </DashboardContainer>
    </>
  );
};

// Preloader Component
const PreloaderComponent = ({ loading }) => {
  return loading ? <Preloader /> : null;
};

// Styled Components
const DashboardContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const AddLeaderContainer = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const FormContainer = styled.form`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
`;
const FormTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: teal;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: darkcyan;
  }
`;

const ProgressBar = styled.div`
  height: 20px;
  background: lightgrey;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;

  &::before {
    content: '';
    display: block;
    height: 100%;
    width: ${({ progress }) => `${progress}%`};
    background: teal;
    transition: width 0.3s;
  }
`;

const LeadersContainer = styled.div`
  flex: 1;
  margin-top: 20px;
`;

const LeadersList = styled.div`
  max-height: 400px; /* Example height, adjust as needed */
  overflow-y: auto;
`;

const LeaderCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const LeaderImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 20px;
`;

const LeaderInfo = styled.div`
  flex: 1;

  h3 {
    margin: 0 0 5px;
    font-size: 1.2rem;
  }

  p {
    margin: 0 0 10px;
    color: #555;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
`;

const EditButton = styled.button`
  padding: 8px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-color: #2980b9;
  }
`;

const DeleteButton = styled.button`
  padding: 8px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #c0392b;
  }
`;

export default LeadersDashboard;
