import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";
import Preloader from "../components/Preloader"; // Import Preloader component

const EditUser = () => {
  const { userid } = useParams(); // Get userid from URL parameter
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    imageUrl: "",
    courses: [], // Initialize courses array
    fees: [], // Initialize fees array
  });
  const [error, setError] = useState(null);
  const [courseDetails, setCourseDetails] = useState({
    centerName: "",
    courseName: "",
    courseFees: "",
    university: "",
    enrollmentSession: "",
    centerHead: "",
    startDate: "",
    endDate: "",
  });
  const [feeDetails, setFeeDetails] = useState({
    amount: "",
    description: "",
    date: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(db, "students", userid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUserDetails({
            ...docSnap.data(),
            courses: docSnap.data().courses || [],
            fees: docSnap.data().fees || [],
          });
        } else {
          setError("User not found");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setError("Error fetching user details");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCourseChange = (e) => {
    const { name, value } = e.target;
    setCourseDetails((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFeeChange = (e) => {
    const { name, value } = e.target;
    setFeeDetails((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddCourse = async (e) => {
    e.preventDefault();

    try {
      const courseData = {
        centerName: courseDetails.centerName,
        courseName: courseDetails.courseName,
        courseFees: parseFloat(courseDetails.courseFees),
        university: courseDetails.university,
        enrollmentSession: courseDetails.enrollmentSession,
        centerHead: courseDetails.centerHead,
        startDate: courseDetails.startDate,
        endDate: courseDetails.endDate,
      };

      const userRef = doc(db, "students", userid);
      await updateDoc(userRef, {
        courses: arrayUnion(courseData),
      });

      setUserDetails((prevData) => ({
        ...prevData,
        courses: [...(prevData.courses || []), courseData],
      }));

      setCourseDetails({
        centerName: "",
        courseName: "",
        courseFees: "",
        university: "",
        enrollmentSession: "",
        centerHead: "",
        startDate: "",
        endDate: "",
      });

      alert("Course added successfully!");
    } catch (error) {
      console.error("Error adding course:", error);
      setError("Error adding course");
    }
  };

  const handleAddFee = async (e) => {
    e.preventDefault();

    try {
      const feeData = {
        amount: parseFloat(feeDetails.amount),
        description: feeDetails.description,
        date: feeDetails.date,
      };

      const userRef = doc(db, "students", userid);
      await updateDoc(userRef, {
        fees: arrayUnion(feeData),
      });

      setUserDetails((prevData) => ({
        ...prevData,
        fees: [...(prevData.fees || []), feeData],
      }));

      setFeeDetails({
        amount: "",
        description: "",
        date: "",
      });

      alert("Fee added successfully!");
    } catch (error) {
      console.error("Error adding fee:", error);
      setError("Error adding fee");
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const userRef = doc(db, "students", userid);
      await updateDoc(userRef, {
        fullName: userDetails.fullName,
        email: userDetails.email,
        mobileNumber: userDetails.mobileNumber,
        imageUrl: userDetails.imageUrl,
        courses: userDetails.courses, // Ensure courses are updated as well
        fees: userDetails.fees, // Ensure fees are updated as well
      });

      alert("User updated successfully!");
    } catch (error) {
      console.error("Error updating user:", error);
      setError("Error updating user");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2>Edit User</h2>
      <form onSubmit={handleUpdateUser} style={styles.form}>
        <input
          type="text"
          name="fullName"
          placeholder="Full Name"
          value={userDetails.fullName}
          onChange={handleChange}
          required
          style={styles.input}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={userDetails.email}
          onChange={handleChange}
          required
          style={styles.input}
        />
        <input
          type="tel"
          name="mobileNumber"
          placeholder="Mobile Number"
          value={userDetails.mobileNumber}
          onChange={handleChange}
          required
          style={styles.input}
        />
        <input
          type="text"
          name="imageUrl"
          placeholder="Image URL"
          value={userDetails.imageUrl}
          onChange={handleChange}
          style={styles.input}
        />
        <button type="submit" style={styles.button}>
          Update User
        </button>
      </form>

      {/* Add course form */}
      <h2>Add Course</h2>
      <form onSubmit={handleAddCourse} style={styles.form}>
        <input
          type="text"
          name="centerName"
          placeholder="Center Name"
          value={courseDetails.centerName}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="text"
          name="courseName"
          placeholder="Course Name"
          value={courseDetails.courseName}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="number"
          name="courseFees"
          placeholder="Course Fees"
          value={courseDetails.courseFees}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="text"
          name="university"
          placeholder="University"
          value={courseDetails.university}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="text"
          name="enrollmentSession"
          placeholder="Enrollment Session"
          value={courseDetails.enrollmentSession}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="text"
          name="centerHead"
          placeholder="Center Head"
          value={courseDetails.centerHead}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="date"
          name="startDate"
          placeholder="Start Date"
          value={courseDetails.startDate}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <input
          type="date"
          name="endDate"
          placeholder="End Date"
          value={courseDetails.endDate}
          onChange={handleCourseChange}
          required
          style={styles.input}
        />
        <button type="submit" style={styles.button}>
          Add Course
        </button>
      </form>

      {/* Add fee form */}
      <h2>Add Fee</h2>
      <form onSubmit={handleAddFee} style={styles.form}>
        <input
          type="number"
          name="amount"
          placeholder="Amount"
          value={feeDetails.amount}
          onChange={handleFeeChange}
          required
          style={styles.input}
        />
        <input
          type="text"
          name="description"
          placeholder="Description"
          value={feeDetails.description}
          onChange={handleFeeChange}
          required
          style={styles.input}
        />
        <input
          type="date"
          name="date"
          placeholder="Date"
          value={feeDetails.date}
          onChange={handleFeeChange}
          required
          style={styles.input}
        />
        <button type="submit" style={styles.button}>
          Add Fee
        </button>
      </form>

      {/* Display user details */}
      <h2>User Details</h2>
      <div style={styles.details}>
        <p><strong>Full Name:</strong> {userDetails.fullName}</p>
        <p><strong>Email:</strong> {userDetails.email}</p>
        <p><strong>Mobile Number:</strong> {userDetails.mobileNumber}</p>
        {userDetails.imageUrl && <img src={userDetails.imageUrl} alt="User" style={styles.image} />}
      </div>

      {/* Display user courses */}
      <h2>Courses</h2>
      {userDetails.courses.length > 0 ? (
        <ul style={styles.list}>
          {userDetails.courses.map((course, index) => (
            <li key={index} style={styles.listItem}>
              <p><strong>Center Name:</strong> {course.centerName}</p>
              <p><strong>Course Name:</strong> {course.courseName}</p>
              <p><strong>Course Fees:</strong> {course.courseFees}</p>
              <p><strong>University:</strong> {course.university}</p>
              <p><strong>Enrollment Session:</strong> {course.enrollmentSession}</p>
              <p><strong>Center Head:</strong> {course.centerHead}</p>
              <p><strong>Start Date:</strong> {course.startDate}</p>
              <p><strong>End Date:</strong> {course.endDate}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No courses added yet.</p>
      )}

      {/* Display user fees */}
      <h2>Fees</h2>
      {userDetails.fees.length > 0 ? (
        <ul style={styles.list}>
          {userDetails.fees.map((fee, index) => (
            <li key={index} style={styles.listItem}>
              <p><strong>Amount:</strong> {fee.amount}</p>
              <p><strong>Description:</strong> {fee.description}</p>
              <p><strong>Date:</strong> {fee.date}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No fees added yet.</p>
      )}
    </div>
  );
};

// Inline CSS styles
const styles = {
  container: {
    padding: "10px",
    fontFamily: "Arial, sans-serif",
    color: "#333",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "500px",
    margin: "auto",
  },
  input: {
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "1rem",
    width: "100%",
  },
  button: {
    padding: "10px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    cursor: "pointer",
    fontSize: "1rem",
    marginTop: "10px",
  },
  details: {
    maxWidth: "500px",
    margin: "20px auto",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  image: {
    maxWidth: "100%",
    borderRadius: "4px",
  },
  list: {
    listStyle: "none",
    padding: "0",
    maxWidth: "500px",
    margin: "20px auto",
  },
  listItem: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    marginBottom: "10px",
  },
};

export default EditUser;
