import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import styled from 'styled-components';
import Preloader from '../components/Preloader'; // Assuming Preloader component exists

const TestimonialsDashboard = () => {
  const [name, setName] = useState('');
  const [socialLinks, setSocialLinks] = useState({ facebook: '', youtube: '', whatsapp: '', instagram: '', twitter: '' });
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [testimonials, setTestimonials] = useState([]);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [loading, setLoading] = useState(false);

  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    const fetchTestimonials = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'testimonials'));
        const testimonialsList = [];
        querySnapshot.forEach((doc) => {
          testimonialsList.push({ id: doc.id, ...doc.data() });
        });
        setTestimonials(testimonialsList);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (selectedTestimonial) {
        if (image) {
          await uploadImageAndSaveTestimonial();
        } else {
          await updateDoc(doc(db, 'testimonials', selectedTestimonial.id), {
            name,
            socialLinks,
            description,
          });
          const updatedTestimonials = testimonials.map(testimonial =>
            testimonial.id === selectedTestimonial.id ? { ...testimonial, name, socialLinks, description } : testimonial
          );
          setTestimonials(updatedTestimonials);
          alert('Testimonial updated successfully!');
        }
      } else {
        if (image) {
          await uploadImageAndSaveTestimonial();
        } else {
          alert('Please upload an image.');
        }
      }
      resetForm();
    } catch (error) {
      console.error('Error adding/updating document:', error);
      alert('Failed to add/update testimonial. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const uploadImageAndSaveTestimonial = () => {
    setLoading(true);
    const imageRef = ref(storage, `testimonials/${image.name}`);
    const uploadTask = uploadBytesResumable(imageRef, image);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error('Upload failed:', error);
        alert('Upload failed. Please try again.');
        setLoading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        try {
          if (selectedTestimonial) {
            await updateDoc(doc(db, 'testimonials', selectedTestimonial.id), {
              name,
              socialLinks,
              description,
              imageUrl: downloadURL,
            });
            const updatedTestimonials = testimonials.map(testimonial =>
              testimonial.id === selectedTestimonial.id ? { ...testimonial, name, socialLinks, description, imageUrl: downloadURL } : testimonial
            );
            setTestimonials(updatedTestimonials);
            alert('Testimonial updated successfully!');
          } else {
            const newTestimonial = {
              name,
              socialLinks,
              description,
              imageUrl: downloadURL,
            };
            const docRef = await addDoc(collection(db, 'testimonials'), newTestimonial);
            setTestimonials([...testimonials, { id: docRef.id, ...newTestimonial }]);
            alert('Testimonial added successfully!');
          }
          resetForm();
        } catch (error) {
          console.error('Error adding/updating document:', error);
          alert('Failed to add/update testimonial. Please try again.');
        } finally {
          setLoading(false);
        }
      }
    );
  };

  const handleEdit = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setName(testimonial.name);
    setSocialLinks(testimonial.socialLinks);
    setDescription(testimonial.description);
  };

  const handleDelete = async (testimonialId) => {
    if (window.confirm('Are you sure you want to delete this testimonial?')) {
      setLoading(true);
      try {
        await deleteDoc(doc(db, 'testimonials', testimonialId));
        setTestimonials(testimonials.filter(testimonial => testimonial.id !== testimonialId));
        alert('Testimonial deleted successfully!');
      } catch (error) {
        console.error('Error deleting testimonial:', error);
        alert('Failed to delete testimonial. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const resetForm = () => {
    setName('');
    setSocialLinks({ facebook: '', youtube: '', whatsapp: '', instagram: '', twitter: '' });
    setDescription('');
    setImage(null);
    setProgress(0);
    setSelectedTestimonial(null);
  };

  return (
    <>
      <PreloaderComponent loading={loading} />
      <DashboardContainer>
        <AddTestimonialContainer>
          <h2>{selectedTestimonial ? 'Edit Testimonial' : 'Add New Testimonial'}</h2>
          <FormContainer onSubmit={handleSubmit}>
            <FormInput
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <FormInput
              type="url"
              placeholder="Facebook URL"
              value={socialLinks.facebook}
              onChange={(e) => setSocialLinks({ ...socialLinks, facebook: e.target.value })}
            />
            <FormInput
              type="url"
              placeholder="YouTube URL"
              value={socialLinks.youtube}
              onChange={(e) => setSocialLinks({ ...socialLinks, youtube: e.target.value })}
            />
            <FormInput
              type="url"
              placeholder="WhatsApp URL"
              value={socialLinks.whatsapp}
              onChange={(e) => setSocialLinks({ ...socialLinks, whatsapp: e.target.value })}
            />
            <FormInput
              type="url"
              placeholder="Instagram URL"
              value={socialLinks.instagram}
              onChange={(e) => setSocialLinks({ ...socialLinks, instagram: e.target.value })}
            />
            <FormInput
              type="url"
              placeholder="Twitter URL"
              value={socialLinks.twitter}
              onChange={(e) => setSocialLinks({ ...socialLinks, twitter: e.target.value })}
            />
            <FormTextArea
              placeholder="A few words about the foundation"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <FormInput type="file" accept="image/*" onChange={(e) => setImage(e.target.files[0])} required />
            <ProgressBar progress={progress} />
            <SubmitButton type="submit">{selectedTestimonial ? 'Update Testimonial' : 'Add Testimonial'}</SubmitButton>
          </FormContainer>
        </AddTestimonialContainer>

        <TestimonialsContainer>
          <h2>Existing Testimonials</h2>
          <TestimonialsList>
            {testimonials.length > 0 ? (
              testimonials.map((testimonial) => (
                <TestimonialCard key={testimonial.id}>
                  <TestimonialImage src={testimonial.imageUrl} alt={testimonial.name} />
                  <TestimonialInfo>
                    <h3>{testimonial.name}</h3>
                    <SocialLinks>
                      {testimonial.socialLinks.facebook && <a href={testimonial.socialLinks.facebook} target="_blank" rel="noopener noreferrer">Facebook</a>}
                      {testimonial.socialLinks.youtube && <a href={testimonial.socialLinks.youtube} target="_blank" rel="noopener noreferrer">YouTube</a>}
                      {testimonial.socialLinks.whatsapp && <a href={testimonial.socialLinks.whatsapp} target="_blank" rel="noopener noreferrer">WhatsApp</a>}
                      {testimonial.socialLinks.instagram && <a href={testimonial.socialLinks.instagram} target="_blank" rel="noopener noreferrer">Instagram</a>}
                      {testimonial.socialLinks.twitter && <a href={testimonial.socialLinks.twitter} target="_blank" rel="noopener noreferrer">Twitter</a>}
                    </SocialLinks>
                    <p>{testimonial.description}</p>
                    <ButtonContainer>
                      <EditButton onClick={() => handleEdit(testimonial)}>Edit</EditButton>
                      <DeleteButton onClick={() => handleDelete(testimonial.id)}>Delete</DeleteButton>
                    </ButtonContainer>
                  </TestimonialInfo>
                </TestimonialCard>
              ))
            ) : (
              <p>No testimonials available.</p>
            )}
          </TestimonialsList>
        </TestimonialsContainer>
      </DashboardContainer>
    </>
  );
};

const PreloaderComponent = ({ loading }) => {
  return loading ? <Preloader /> : null;
};

// Styled Components
const DashboardContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const AddTestimonialContainer = styled.div`
  flex: 1;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormInput = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const FormTextArea = styled.textarea`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 80px;
`;

const SubmitButton = styled.button`
  padding: 10px;
  background-color: teal;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: darkcyan;
  }
`;

const TestimonialsContainer = styled.div`
  flex: 2;
  max-width: 100%;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const TestimonialsList = styled.div`
  display: flex;
  flex-direction: column;
`;

const TestimonialCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TestimonialImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
`;

const TestimonialInfo = styled.div`
  flex: 1;
`;

const SocialLinks = styled.div`
  margin: 10px 0;
  a {
    margin-right: 10px;
    color: #3498db;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
`;

const EditButton = styled.button`
  padding: 8px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-color: #2980b9;
  }
`;

const DeleteButton = styled.button`
  padding: 8px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #c0392b;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  background-color: #ddd;
  border-radius: 4px;
  margin-bottom: 10px;

  &::after {
    content: '';
    display: block;
    height: 10px;
    border-radius: 4px;
    background-color: #4caf50;
    width: ${props => props.progress}%;
  }
`;

export default TestimonialsDashboard;
