import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getFirestore, collection, addDoc, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Preloader from '../components/Preloader'; // Adjust the path as per your project structure

const SliderDashboard = () => {
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [sliderItems, setSliderItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true); // New state for loading indicator

  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    const fetchSliderItems = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'sliderItems'));
        const itemsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSliderItems(itemsList);
      } catch (error) {
        console.error('Error fetching slider items:', error);
      } finally {
        setLoading(false); // Update loading state after fetching
      }
    };

    fetchSliderItems();
  }, [db]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true on form submit

    if (selectedItem) {
      // Edit existing slider item
      if (image) {
        // If new image is selected, upload it
        uploadImageAndSaveItem();
      } else {
        // If no new image, update with existing image URL
        try {
          await updateDoc(doc(db, 'sliderItems', selectedItem.id), {
            title,
            link,
          });
          const updatedItems = sliderItems.map(item =>
            item.id === selectedItem.id ? { ...item, title, link } : item
          );
          setSliderItems(updatedItems);
          alert('Slider item updated successfully!');
          resetForm();
        } catch (error) {
          console.error('Error updating slider item:', error);
          alert('Failed to update slider item. Please try again.');
        }
      }
    } else {
      // Add new slider item
      if (image) {
        // If new image is selected, upload it
        uploadImageAndSaveItem();
      } else {
        // Use default image URL if no image is selected
        const defaultImageUrl = 'default_image_url'; // Replace with your default image URL
        const newItem = {
          title,
          link,
          imageUrl: defaultImageUrl,
        };
        try {
          const docRef = await addDoc(collection(db, 'sliderItems'), newItem);
          setSliderItems([...sliderItems, { id: docRef.id, ...newItem }]);
          alert('Slider item added successfully!');
          resetForm();
        } catch (error) {
          console.error('Error adding slider item:', error);
          alert('Failed to add slider item. Please try again.');
        }
      }
    }

    setLoading(false); // Set loading to false after async operations
  };

  const uploadImageAndSaveItem = () => {
    const imageRef = ref(storage, `slider/${image.name}`);
    const uploadTask = uploadBytesResumable(imageRef, image);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error('Upload failed:', error);
        alert('Upload failed. Please try again.');
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          try {
            if (selectedItem) {
              // Update existing slider item with new image URL
              await updateDoc(doc(db, 'sliderItems', selectedItem.id), {
                title,
                link,
                imageUrl: downloadURL,
              });
              const updatedItems = sliderItems.map(item =>
                item.id === selectedItem.id ? { ...item, title, link, imageUrl: downloadURL } : item
              );
              setSliderItems(updatedItems);
              alert('Slider item updated successfully!');
            } else {
              // Add new slider item with image URL
              const newItem = {
                title,
                link,
                imageUrl: downloadURL,
              };
              const docRef = await addDoc(collection(db, 'sliderItems'), newItem);
              setSliderItems([...sliderItems, { id: docRef.id, ...newItem }]);
              alert('Slider item added successfully!');
            }
            resetForm();
          } catch (error) {
            console.error('Error adding/updating slider item:', error);
            alert('Failed to add/update slider item. Please try again.');
          }
        });
      }
    );
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setTitle(item.title);
    setLink(item.link);
  };

  const handleDelete = async (itemId) => {
    if (window.confirm('Are you sure you want to delete this slider item?')) {
      try {
        await deleteDoc(doc(db, 'sliderItems', itemId));
        setSliderItems(sliderItems.filter(item => item.id !== itemId));
        alert('Slider item deleted successfully!');
      } catch (error) {
        console.error('Error deleting slider item:', error);
        alert('Failed to delete slider item. Please try again.');
      }
    }
  };

  const resetForm = () => {
    setTitle('');
    setLink('');
    setImage(null);
    setProgress(0);
    setSelectedItem(null);
  };

  return (
    <DashboardContainer>
      {loading && <Preloader />} {/* Show Preloader if loading state is true */}

      <AddItemContainer>
        <h2>{selectedItem ? 'Edit Slider Item' : 'Add New Slider Item'}</h2>
        <FormContainer onSubmit={handleSubmit}>
          <FormInput
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <FormInput
            type="text"
            placeholder="Link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            required
          />
          <FormInput type="file" accept="image/*" onChange={(e) => setImage(e.target.files[0])} />
          <ProgressBar progress={progress} />
          <SubmitButton type="submit">{selectedItem ? 'Update Item' : 'Add Item'}</SubmitButton>
        </FormContainer>
      </AddItemContainer>

      <SliderItemsContainer>
        <h2>Slider Items</h2>
        <ItemsList>
          {sliderItems.length > 0 ? (
            sliderItems.map((item) => (
              <ItemCard key={item.id}>
                <ItemImage src={item.imageUrl} alt={item.title} />
                <ItemInfo>
                  <h3>{item.title}</h3>
                  {item.link}
                  <ButtonContainer>
                    <EditButton onClick={() => handleEdit(item)}>Edit</EditButton>
                    <DeleteButton onClick={() => handleDelete(item.id)}>Delete</DeleteButton>
                  </ButtonContainer>
                </ItemInfo>
              </ItemCard>
            ))
          ) : (
            <p>No slider items available.</p>
          )}
        </ItemsList>
      </SliderItemsContainer>
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 10px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const AddItemContainer = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const FormContainer = styled.form`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: teal;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: darkcyan;
  }
`;

const ProgressBar = styled.div`
  height: 20px;
  background: lightgrey;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;

  &::before {
    content: '';
    display: block;
    height: 100%;
    width: ${({ progress }) => `${progress}%`};
    background: teal;
    transition: width 0.3s;
  }
`;

const SliderItemsContainer = styled.div`
  flex: 1;
  margin-top: 20px;
`;

const ItemsList = styled.div`
  max-height: 400px; /* Example height, adjust as needed */
  overflow-y: auto;
`;

const ItemCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ItemImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 20px;
`;

const ItemInfo = styled.div`
  flex: 1;

  h3 {
    margin: 0 0 5px;
    font-size: 1.2rem;
  }

  p {
    margin: 0 0 10px;
    color: #555;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
`;

const EditButton = styled.button`
  padding: 8px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-color: #2980b9;
  }
`;

const DeleteButton = styled.button`
  padding: 8px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #c0392b;
  }
`;

export default SliderDashboard;
