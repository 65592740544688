// Footer.js
import React from 'react';
import styled from 'styled-components';

// Styled components for the footer
const FooterContainer = styled.footer`
  background-color: teal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: white;
  width: 100;
  bottom: 0;
  z-index: 1000; /* Ensure the footer is above other elements */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;

// The functional component for the footer
const Footer = () => {
  return (
    <FooterContainer>
      <p>&copy; 2024 Sreef. All rights reserved.</p>
    </FooterContainer>
  );
};

export default Footer;
