import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Adjust the path as per your project structure
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Preloader from "../components/Preloader"; // Adjust the path as per your project structure

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(5); // Default page size
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "students"));
        const usersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersList);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSortChange = (field) => {
    const sortedUsers = [...users].sort((a, b) => {
      if (sortDirection === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });
    setUsers(sortedUsers);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortFieldChange = (e) => {
    handleSortChange(e.target.value);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1); // Reset to first page
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const deleteUser = async (userId, userName) => {
    if (window.confirm(`Are you sure you want to delete ${userName}?`)) {
      try {
        await deleteDoc(doc(db, "students", userId));
        setUsers(users.filter((user) => user.id !== userId));
        alert(`${userName} has been deleted successfully.`);
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  // Filter and sort users based on search term
  const filteredUsers = users?.filter((user) =>
    user.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  // Paginate the filtered users
  const totalPages = Math.ceil(filteredUsers.length / pageSize);
  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  if (loading) {
    return <Preloader />;
  }

  return (
    <div style={styles.container}>
      <div style={styles.searchSortContainer}>
        <input
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearchChange}
          style={styles.searchInput}
        />
        <select onChange={handleSortFieldChange} style={styles.sortDropdown}>
          <option value="fullName">Sort by Name</option>
          <option value="username">Sort by Username</option>
          <option value="email">Sort by Email</option>
          <option value="mobileNumber">Sort by Mobile Number</option>
        </select>
        <select onChange={handlePageSizeChange} style={styles.pageSizeDropdown}>
          <option value="5">5 rows</option>
          <option value="10">10 rows</option>
          <option value="20">20 rows</option>
          <option value="50">50 rows</option>
        </select>
      </div>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Profile</th>
            <th style={styles.th}>Username</th>
            <th style={styles.th}>Full Name</th>
            <th style={styles.th}>Email</th>
            <th style={styles.th}>Mobile Number</th>
            <th style={styles.th}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedUsers.map((user) => (
            <tr key={user.id} style={styles.tr}>
              <td style={styles.td}>
                <div style={styles.profileContainer}>
                  {user.imageUrl ? (
                    <img
                      src={user.imageUrl}
                      alt={user.fullName}
                      style={styles.profileImage}
                    />
                  ) : (
                    <div style={styles.profileIcon}>
                      {user.fullName.charAt(0)}
                    </div>
                  )}
                </div>
              </td>
              <td style={styles.td}>{user.username}</td>
              <td style={styles.td}>{user.fullName}</td>
              <td style={styles.td}>{user.email}</td>
              <td style={styles.td}>{user.mobileNumber}</td>
              <td style={styles.td}>
                <button
                  onClick={() => navigate(`/edituser/${user.id}`)}
                  style={styles.detailButton}
                >
                  Details →
                </button>
                <button
                  onClick={() => navigate(`/usercourse/${user.id}`)}
                  style={styles.detailButton}
                >
                  Add Course →
                </button>
                <button
                  onClick={() => navigate(`/userfees/${user.id}`)}
                  style={styles.detailButton}
                >
                  Add Fees →
                </button>
                <button
                  onClick={() => navigate(`/edituser/${user.id}`)}
                  style={styles.detailButton}
                >
                  Fees →
                </button> 
                <button
                  onClick={() => navigate(`/usercertificate/${user.id}`)}
                  style={styles.detailButton}
                >
                  Add Certificate →
                </button>
                <button
                  onClick={() => {
                    const newWindow = window.open(`/userfulldetails/${user.id}`, "_blank", "toolbar=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600");
                    if (newWindow) newWindow.focus();
                  }}
                  style={styles.detailButton}
                >
                  Full Details →
                </button>
                <button
                  onClick={() => deleteUser(user.id, user.fullName)}
                  style={styles.deleteButton}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={styles.paginationContainer}>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            style={styles.paginationButton}
            disabled={currentPage === index + 1}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

// Inline CSS styles
const styles = {
  container: {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    color: "#333",
    maxWidth: "100%",
    overflowX: "auto",
  },
  searchSortContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: "20px",
  },
  searchInput: {
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "1rem",
    flex: "1 1 45%", // Flex to 45% width
    marginBottom: "10px",
  },
  sortDropdown: {
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "1rem",
    flex: "1 1 20%", // Flex to 20% width
    marginBottom: "10px",
  },
  pageSizeDropdown: {
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "1rem",
    flex: "1 1 20%", // Flex to 20% width
    marginBottom: "10px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
  },
  td: {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "left",
  },
  tr: {
    backgroundColor: "#fff",
    "&:nth-child(even)": {
      backgroundColor: "#f9f9f9",
    },
    "&:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
  profileContainer: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "#f0f0f0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  profileImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  profileIcon: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#555",
  },
  detailButton: {
    padding: "5px 10px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    cursor: "pointer",
    fontSize: "0.9rem",
    margin: "5px 0",
  },
  deleteButton: {
    padding: "5px 10px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#dc3545",
    color: "#fff",
    cursor: "pointer",
    fontSize: "0.9rem",
    margin: "5px 0",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  paginationButton: {
    padding: "10px",
    margin: "0 5px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    backgroundColor: "#f8f8f8",
    cursor: "pointer",
  },
};

export default UserList;
